import React, { useEffect, useState } from "react";
import { VerifyOtpEmail } from "./lightbox/VerifyOtpEmail";
import { VerificationCodeHasExpired } from "./lightbox/VerificationCodeHasExpired";
import { EmailVerified } from "./lightbox/EmailVerified";
import { EnterNewPassword } from "./lightbox/EnterNewPassword";
import { PasswordNotChanged } from "./lightbox/PasswordNotChanged";
import { LightBoxEnum } from "./Enum";

const ActiveLightbox = ({ activeLightbox, email, onClose, onOpenLightbox, password, triggerButtonRef, onSubmit, submitFormVal, isFromCCDB }) => {
  const [isPasswordNotChangedOpen, setPasswordNotChangedOpen] = useState(false);
  const [isEnterNewPasswordOpen, setEnterNewPasswordOpen] = useState(false);

  useEffect(() => {
    switch (activeLightbox) {
      case LightBoxEnum.EmailOtpCode:
        onOpenLightbox(LightBoxEnum.EmailOtpCode);
        break;
      case LightBoxEnum.EmailResend:
        onOpenLightbox(LightBoxEnum.EmailResend);
        break;
      case LightBoxEnum.EmailResendfromExpired:
        onOpenLightbox(LightBoxEnum.EmailResendfromExpired);
        break;
      case LightBoxEnum.EmailOtpCodeExpired:
        onOpenLightbox(LightBoxEnum.EmailOtpCodeExpired);
        break;
      case LightBoxEnum.EmailVerified:
        onOpenLightbox(LightBoxEnum.EmailVerified);
        break;
      case LightBoxEnum.PasswordNotChanged:
        setPasswordNotChangedOpen(true);
        break;
      default:
        break;
    }
  }, [activeLightbox, onOpenLightbox]);

  // Handles the action to open the "Enter New Password" modal.
  // Closes the "Password Not Changed" modal and opens the "Enter New Password" modal.
  const handleOpenEnterNewPassword = () => {
    setPasswordNotChangedOpen(false);
    setEnterNewPasswordOpen(true);
  };

  const handleCloseEnterNewPassword = () => {
    setEnterNewPasswordOpen(false);
    setPasswordNotChangedOpen(false);
    onClose();
  };

  const handlePasswordNotChangedCancelClick = () => {
    onSubmit(submitFormVal, { setSubmitting: () => { } });
    onClose();
  };

  useEffect(() => {
    if (activeLightbox) {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.querySelectorAll('main>*:not(.agw-lightbox)').forEach(el => el.setAttribute('aria-hidden', 'true'));

      const lightboxInput = document.querySelector('.agw-lightbox input');
      const lightboxHeading = document.querySelector('.agw-lightbox h5');

      if (lightboxInput) {
        lightboxInput.focus();
      } else if (lightboxHeading) {
        lightboxHeading.focus();
      }

    } else {
      document.body.removeAttribute('style');
      document.querySelectorAll('main>*:not(.agw-lightbox)').forEach(el => el.removeAttribute('aria-hidden'));

      if (triggerButtonRef.current) {
        triggerButtonRef.current.focus();
      }
    }
  }, [activeLightbox]);

  return (
    <>
      {activeLightbox === LightBoxEnum.EmailOtpCode && (
        <VerifyOtpEmail
          autoSend={false}
          email={email}
          onClose={onClose}
          onOpenLightbox={onOpenLightbox}
          triggerButtonRef={triggerButtonRef}
          isFromCCDB={isFromCCDB}
        />
      )}
      {activeLightbox === LightBoxEnum.EmailResend && (
        <VerifyOtpEmail
          autoSend={false}
          email={email}
          onClose={onClose}
          onOpenLightbox={onOpenLightbox}
          triggerButtonRef={triggerButtonRef}
          isFromCCDB={isFromCCDB}
        />
      )}
      {activeLightbox === LightBoxEnum.EmailResendfromExpired && (
        <VerifyOtpEmail
          autoSend={true}
          email={email}
          onClose={onClose}
          onOpenLightbox={onOpenLightbox}
          triggerButtonRef={triggerButtonRef}
          isFromCCDB={isFromCCDB}
        />
      )}
      {activeLightbox === LightBoxEnum.EmailOtpCodeExpired && (
        <VerificationCodeHasExpired
          isOpen={true}
          email={email}
          onClose={onClose}
          onOpenLightbox={onOpenLightbox}
          triggerButtonRef={triggerButtonRef}
          isFromCCDB={isFromCCDB}
        />
      )}
      {activeLightbox === LightBoxEnum.EmailVerified && (
        <EmailVerified isOpen={true} onClose={onClose} />
      )}
      {activeLightbox === LightBoxEnum.PasswordNotChanged && (
        <>
          <PasswordNotChanged
            isOpen={isPasswordNotChangedOpen}
            onClose={handlePasswordNotChangedCancelClick}
            onChangePasswordClick={handleOpenEnterNewPassword}
          />
          <EnterNewPassword
            isOpen={isEnterNewPasswordOpen}
            onClose={handleCloseEnterNewPassword}
            password={password}
            email={email}
          />
        </>
      )}

    </>
  );
};

export default ActiveLightbox;
