import React, { useEffect, useState } from "react";
import {useParams } from "react-router-dom";


function Footer() {
  const locale = useParams().locale;
  const [body, setBody] = useState("");


  // Get footer
  useEffect(() => {
    const fetchFooter = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/${locale}/api/account/getlayout?type=footer`, {
          method: 'GET'
        });
        if (response.ok) {
          const footerHtml = await response.text();
          setBody(footerHtml);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFooter();
  }, [locale]);

 
  return (
    <div dangerouslySetInnerHTML={{ __html: body  }} />
  );
}

export default Footer;
