import { React, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { TranslationContext } from "contexts/TranslationProvider";
import LeftMenu from "components/LeftMenu";
import DeleteProduct from "components/DeleteProduct";
import * as utils from "../helpers/utilityHelpers";
import { useLoading } from "../contexts/LoadingProvider";

function MyProducts() {
	const locale = useParams().locale;
	const { isLoading, setIsLoading } = useLoading();
	const translations = useContext(TranslationContext);
	const [products, setProducts] = useState([]);
	const [campaigns, setCampaigns] = useState([]);
	const [selectCampaigns, setSelectCampaigns] = useState([]);
	const [CampaignsFromCMS, setCampaignsFromCMS] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedModel, setSelectedModel] = useState("");
	const [selectedSn, setSelectedSn] = useState("");
	document.title = "My Products | " + translations.siteName;
	useEffect(() => {
		setIsLoading(true);		
		const fetchSelectItems = async () => {
			try {
				const url = `${process.env.REACT_APP_URL}/${locale}/api/account/my-products`;
				const res = await utils.fetchApi(url);

				if (res.redirectUrl) {
					window.location.href = res.redirectUrl;
				}

				setProducts(res.products);
				setCampaigns(res.campaigns);
				setSelectCampaigns(res.selectCampaigns);
				setCampaignsFromCMS(res.CampaignsFromCMS);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchSelectItems();
	}, []);

	// Open delete lightbox
	const handleOpenDeleteLightbox = (e) => {
		setSelectedModel(e.model);
		setSelectedSn(e.serialNumber);
		setIsOpen(true);
		document.querySelector('body').setAttribute('aria-hidden', 'true');
	};

	// Close delete lightbox
	const handleCloseDeleteLightbox = (e) => {
		setIsOpen(false);
		document.querySelector('body').removeAttribute('aria-hidden');
		handleFocusBtn();
	};

	// Close delete lightbox on escape key
	const handleKeyDown = (e) => {
		if (e.key === "Escape") {
			handleCloseDeleteLightbox(e);
			handleFocusBtn();
		}
	};

	// Handle focus on first button when lightbox open
	const handleFirstFocus = () => {
		if (isOpen) {
			const firstButton = document.querySelector('.agw-icon-x-mark');
			if (firstButton) {
				firstButton.focus();
			}
		}
	};

	// Handle focus on button when lightbox close
	const handleFocusBtn = () => {
		const button = document.querySelector(`[data-id="${selectedSn}"]`);
		if (button) {
			button.focus();
		}
	};

	// Add event listener to close lightbox on escape key
	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	// Tab only in lightbox
	const handleTabKey = (e) => {
		if (e.key === "Tab" && isOpen) {
			const focusableModalElements = document.querySelectorAll('[role="dialog"] button');
			const firstElement = focusableModalElements[0];
			const lastElement = focusableModalElements[focusableModalElements.length - 1];

			if (e.shiftKey) {
				if (document.activeElement === firstElement) {
					e.preventDefault();
					lastElement.focus();
				}
			} else {
				if (document.activeElement === lastElement) {
					e.preventDefault();
					firstElement.focus();
				}
			}
		}
	};

	useEffect(() => {
		handleFirstFocus();
		document.addEventListener("keydown", handleTabKey);
		return () => {
			document.removeEventListener("keydown", handleTabKey);
		};
	}, [isOpen]);

	return (
		<>
			<LeftMenu />
			<div className="account__content">
				<div className="section section--padding-bottom light">
					<div className="section__header">
						<h1 className="section__title agw-fs-headline-m">{translations.accountProductHeadline}</h1>
						<p className="mb-0">{translations.accountProductRegisteredProducts}</p>
					</div>
					{products.length > 0 ? (
						<div className="section__body">
							{/* <input name="__RequestVerificationToken" type="hidden" value="jD1O50rEz9dGU5mHI6tCSLUAEA11ndL-1h-YCCOwcFh8mphBa4sJl2GPetzr4Q4mLbH-AUEaz-s9QvAbaa1autVw9emDj5Mu0WjbkRR9_bk1" /> */}
							{products.map((item) => (
								<div className="section section--border-bottom white" key={item.serialNumber}>
									<div className="section__header">
										<div className="card card--horizontal@md align-items-end" href="#">
											<a href={item.supportPageUrl} target="_self">
												<img className="card__img card__img--horizontal@sm" src={item.image} alt={item.partNumber} />
											</a>
											<div className="card__body--center-left m-auto m-md-0 mb-md-3">
												<h2 className="card__title agw-fs-title-l">{item.model}</h2>
												<p className="card__text agw-fs-body-m mb-1">{item.serialNumber}</p>
												<button
													className="card__link agw-fs-label remove-item agw-btn agw-text-decoration-underline agw-p-0"
													data-model={item.model}
													data-id={item.serialNumber}
													onClick={() => { handleOpenDeleteLightbox(item); }}
												>
													{translations.Remove}
												</button>

											</div>
										</div>
									</div>
									{selectCampaigns.map((SelectCampaignsItem) => (
										SelectCampaignsItem.serialNumber === item.serialNumber ? (
											<div className="section__body" key={SelectCampaignsItem.serialNumber}>
												<p className="agw-fs-body-l mb-1 card__body--center-left">{translations.AvailableOffer}</p>
												<ul className="card-list card-list--fixed-3">
													<li className="card-list__item" key={SelectCampaignsItem.serialNumber}>
														<div className="pt card box--border">
															<div className="card__body">
																<h3 className="card__title card__title--secondary agw-fs-body-s">
																	{SelectCampaignsItem.title}
																</h3>
																<p className="card__tags card__tags--10 promo_code"></p>
																<p className="card__tags card__tags--10" id="promo-expire-date" style={{ display: "none" }}>{translations.ValidTil}</p>
																<p className="card__text agw-fs-body-s">{SelectCampaignsItem.desc}</p>
															</div>
															<div className="card__footer p-0">
																<p className="card__link agw-fs-label campaignlink">
																	{/*  onClick={(event) => { dataLayer.push({ 'event': 'ct_campaign list', 'eventAction': 'campaign list', 'eventLabel': '¡Opina y gana!', 'eventParam1': '¡Opina y gana!', 'click_url': 'https://newagws-prd.intra.acer.com/es-es/opinaygana/index.html' }); window.event.stopPropagation(); }}  */}
																	<a href={SelectCampaignsItem.link} target="_self">{SelectCampaignsItem.linktext}</a>
																</p>
															</div>
														</div>
													</li>
												</ul>
											</div>
										) : null
									))}
								</div>
							))}
							<DeleteProduct isOpen={isOpen} onClose={handleCloseDeleteLightbox} Model={selectedModel} Sn={selectedSn} />
						</div>
					) : ""}
				</div>
			</div>
		</>
	)

}

export default MyProducts;