import { React, useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import LeftMenu from "components/LeftMenu";
import useLightbox from "../hooks/useLightbox";
import { LightBoxEnum } from "components/Enum";
import { TranslationContext } from "contexts/TranslationProvider";
import * as validationSchemas from "../validation/validationSchemas";
import * as utils from "../helpers/utilityHelpers";
import { useFormik } from "formik";
import { useLoading } from "../contexts/LoadingProvider";
import ActiveLightbox from "../components/ActiveLightbox";


function MyProfile() {
	const locale = useParams().locale;
	const translations = useContext(TranslationContext);
	const { isLoading, setIsLoading } = useLoading();
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const [isReady, setIsReady] = useState(false);
	const [passwordStatus, setPasswordStatus] = useState(false);
	const [activeLightbox, openLightbox, closeAllLightboxes] = useLightbox();
	const triggerButtonRef = useRef(null);

	const [countrySelectItems, setCountrySelectItems] = useState([]);
	const [languageSelectItems, setLanguageSelectItems] = useState([]);
	const [phonecodeSelectItems, setPhonecodeSelectItems] = useState([]);
	const [billingFormCheck, setBillingFormCheck] = useState(false);
	const [billingFormDisabled, setBillingFormDisabled] = useState(true);
	const [isSaveBoxVisible, setIsSaveBoxVisible] = useState(false);
	const [initialData, setInitialData] = useState(null);
	const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);
	
	document.title = "My Profile | " + translations.siteName;

	const prepareFormikValues = (res) => {
		return {
		  ...res,
		  newPassword: "",
		  confirmPassword: "",
		  phonePrefixCode: res.phonePrefixCode || "",
		  phoneNumber: res.phoneNumber || "",
		  mobilePhonePrefixCode: res.mobilePhonePrefixCode || "",
		  mobilePhoneNumber: res.mobilePhoneNumber || "",
		  shippingAddr: {
			address: res.shippingAddr?.address || "",
			address2: res.shippingAddr?.address2 || "",
			city: res.shippingAddr?.city || "",
			state: res.shippingAddr?.state || "",
			postcode: res.shippingAddr?.postcode || "",
			countryCode: res.shippingAddr?.countryCode || ""
		  },
		  billingAddr: {
			address: res.billingAddr?.address || "",
			address2: res.billingAddr?.address2 || "",
			city: res.billingAddr?.city || "",
			state: res.billingAddr?.state || "",
			postcode: res.billingAddr?.postcode || "",
			countryCode: res.billingAddr?.countryCode || ""
		  }
		};
	  };


	useEffect(() => {
		setIsLoading(true);
		const fetchSelectItems = async () => {
			try {
				const url = `${process.env.REACT_APP_URL}/${locale}/api/account/my-profile`;
				const res = await utils.fetchApi(url);

				if (res.redirectUrl) {
					window.location.href = res.redirectUrl;
				}
				
				setInitialData(res);
				formik.setValues(prepareFormikValues(res));
				setCountrySelectItems(res.countrySelectItems);
				setLanguageSelectItems(res.languageSelectItems);
				setPhonecodeSelectItems(res.phonecodeSelectItems);
				
				setIsReady(true);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);			
			}
		};
		fetchSelectItems();
	}, []);


	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			countryCode: "",
			languageCode: "",
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
			email: "",
			phonePrefixCode: "",
			phoneNumber: "",
			mobilePhonePrefixCode: "",
			mobilePhoneNumber: "",
			shippingAddr: {
				address: "",
				address2: "",
				city: "",
				state: "",
				postcode: "",
				countryCode: "",
			},
			billingAddr: {
				address: "",
				address2: "",
				countryCode: "",
				city: "",
				postcode: "",
				state: "",
			},
			contactAgreement: false
		},
		validationSchema: validationSchemas.myProfileValidationSchema(translations),
		onSubmit: async (values) => {
			setErrorMsg("");
			setIsLoading(true);
			try {
				const url = `${process.env.REACT_APP_URL}/${locale}/api/account/my-profile`;
				const res = await utils.fetchApi(url, "POST", values);
				if (res.redirectUrl) {
					window.location.href = res.redirectUrl;
				}

				if (res.success) {
					// openLightbox(LightBoxEnum.EmailOtpCode);
					// } else {
					if (res.successMsg) {
						switch (res.successMsg) {
							case "PopupOTP":
								openLightbox(LightBoxEnum.EmailOtpCode);
								break;
							default:
								setSuccessMsg(res.successMsg);
								break;
						}
					}
					setInitialData(values);
					setBillingFormDisabled(false);
					setErrorMsg("");
				}
				else {
					setErrorMsg(res.errorMsg);
					let errorInput = null;
					res.errors.forEach(async (error) => {
						var id = `${error.id}`.charAt(0).toLowerCase() + `${error.id}`.slice(1) //firtstchar tolowercase
						await formik.setFieldError(id, error.errorMsg);
						if (errorInput === null) {
							errorInput = document.getElementsByName(id)[0];
							errorInput.focus();
						}
					});
				}
			} catch (error) {
				console.log(error);
			} finally {
				setIsLoading(false);
				setIsSaveBoxVisible(false);
			}
		},
	});

	// Copy checkbox status
	useEffect(() => {
		// If shipping address is change, billing form copy checkebox uncheck
		setBillingFormCheck(false);

		// If shipping address is empty, disable billing form copy checkebox
		const { address, address2, city, state, postcode, countryCode } = formik.values.shippingAddr;
		const isShippingAddrEmpty = !address && !address2 && !city && !state && !postcode && !countryCode;

		if (isShippingAddrEmpty || (!address && !address2)) {
			setBillingFormDisabled(true);
		} else {
			setBillingFormDisabled(false);
		}
	}, [formik.values.shippingAddr]);

	useEffect(() => {
		const shippingAddr = formik.values.shippingAddr;
		const billingAddr = formik.values.billingAddr;

		// compare value
		const keysToCompare = [
			"address",
			"address2",
			"city",
			"state",
			"postcode",
			"countryCode",
		];

		const isSame = keysToCompare.every(
			(key) => shippingAddr[key] === billingAddr[key]
		);

		if (!isSame) {
			setBillingFormCheck(false);
		} else {
			setBillingFormCheck(true);
		}
	}, [formik.values.shippingAddr, formik.values.billingAddr]);

	if (!isReady) {
		return null;
	}

	// handle input change then show save box
	const handleInputChange = (e) => {
		setSuccessMsg("");
		formik.handleChange(e);
		const { name, value } = e.target;
		if (initialData && initialData[name] !== value) {
			setIsSaveBoxVisible(true);
		} else {
			setIsSaveBoxVisible(false);
		}
	};

	// Copy function
	const handlebillingFormCheck = () => {
		setBillingFormCheck(!billingFormCheck);
		setIsSaveBoxVisible(true);
		if (formik.values.shippingAddr) {
			formik.setFieldValue("billingAddr", formik.values.shippingAddr);
		}
	}

	
	// Reset the form to its initial values (Cancel button functionality)
	const handleCancel = () => {
		formik.setValues(prepareFormikValues(initialData));
		setIsSaveBoxVisible(false);
		setPasswordStatus(false);
		setSuccessMsg("");
	};

	// Handle number input change event, remove non-numeric characters
	const handleNumberFormat = (field) => (event) => {
		const value = event.target.value.replace(/[^0-9\s]/g, '');
		handleInputChange(event);
		formik.setFieldValue(field, value);
	};

	// password input change event, delete old password input value
	const handlePasswordChange = (e) => {
		formik.handleChange(e);
		setIsSaveBoxVisible(true);
		formik.setFieldValue("oldPassword", "");
	};

	// Handle input focus style
	const handleFocus = () => {
		document.addEventListener('keydown', (e) => {
			if (['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
				setIsKeyboardNavigation(true);
			}
		});

		document.addEventListener('mousedown', () => {
			setIsKeyboardNavigation(false);
		});

		document.querySelectorAll('.input__text,.input__select').forEach((input) => {
			input.addEventListener('focusin', () => {
				if (isKeyboardNavigation) {
					input.style.outline = '#0071e3 solid 2px';
					input.style.outlineOffset = '-2px';
				} else {
					input.style.outline = '';
					input.style.outlineOffset = '';
				}
			});

			input.addEventListener('focusout', () => {
				input.style.outline = '';
				input.style.outlineOffset = '';
			});
		});
	}

	


	handleFocus();

	return (
		<>
			<LeftMenu />
			<div className="account__content">
				<form id="main-form" onSubmit={formik.handleSubmit}>
					<input name="__RequestVerificationToken" type="hidden" value="yogyvwurDgXIVvSrwlUCod-vmJTwx46icC-6QTc_LNRHMiFcDqUjbEs648IpJWEhAsUx3MFeStpX_pBhyUEFJVmBeMr8GG----wZ-8CxIyE1" />
					<input id="OldEmail" name="OldEmail" type="hidden" value={formik.values.OldEmail} />

					{/* Profile */}
					<fieldset className="form-panel">
						<legend className="agw-mb-0">
							<h1 className="agw-fs-title-l">{translations.profileHeader}</h1>
						</legend>
						<div className="input">
							<input
								type="text"
								aria-required="true"
								className="input__text"
								id="firstName"
								name="firstName"
								placeholder=" "
								value={formik.values.firstName || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							/>
							<label htmlFor="firstName" className="input__label">{translations.firstNameMyProfile}</label>
							{formik.touched.firstName &&
								(formik.errors.firstName) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="FirstNameError"
									aria-live="assertive" aria-atomic="true"
								>{formik.errors.firstName}</span>
							) : null}
						</div>
						<div className="input">
							<input
								aria-required="true"
								className="input__text"
								id="lastName"
								name="lastName"
								placeholder=" "
								type="text"
								value={formik.values.lastName || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							/>
							<label htmlFor="lastName" className="input__label">{translations.lastNameMyProfile}</label>
							{formik.touched.lastName &&
								(formik.errors.lastName) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="LastNameError"
									aria-live="assertive"
									aria-atomic="true"
								>{formik.errors.lastName}</span>
							) : null}
						</div>
						<div className="input">
							<select
								id="countryCode"
								name="countryCode"
								className="input__select"
								aria-required="true"
								value={formik.values.countryCode || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							>
								{countrySelectItems.map((item) => (
									<option key={item.value} value={item.value}>
										{item.text}
									</option>
								))}
							</select>
							<label htmlFor="countryCode" className="input__label">{translations.countryMyProfile}</label>
							{formik.touched.countryCode &&
								(formik.errors.countryCode) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="CountryCodeError"
									aria-live="assertive"
									aria-atomic="true"
								>{formik.errors.countryCode}</span>
							) : null}
						</div>
						<div className="input">
							<input
								aria-label={translations.passwordMyProfile}
								aria-required="true"
								className="input__text"
								id="oldPassword"
								name="oldPassword"
								placeholder=" "
								type="password"
								value={formik.values.oldPassword || ""}
								onChange={handleInputChange}
								onClick={handlePasswordChange}
								onBlur={formik.handleBlur}
								onFocus={() => { setPasswordStatus(true); }}
							/>
							<label htmlFor="oldPassword" className="input__label" id="OldPasswordLabel">{translations.passwordMyProfile}</label>
							{formik.touched.oldPassword &&
								(formik.errors.oldPassword) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="OldPasswordError"
									aria-live="assertive"
									aria-atomic="true"
								>{formik.errors.oldPassword}</span>
							) : null}
						</div>
						<div className="input" style={{ display: passwordStatus ? "block" : "none" }}>
							<input
								aria-label={translations["New Password"]}
								aria-required="true"
								className="input__text"
								id="newPassword"
								name="newPassword"
								placeholder=" "
								type="password"
								value={formik.values.newPassword || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							/>
							<label htmlFor="newPassword" className="input__label">{translations["New Password"]}</label>
							<p className="input__hint" id="Password_Hint"></p>
							{(formik.errors.newPassword) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="newPasswordError"
									aria-live="assertive" aria-atomic="true"
								>{formik.errors.newPassword}</span>
							) : null}
						</div>
						<div className="input" style={{ display: passwordStatus ? "block" : "none" }}>
							<input
								aria-label={translations["Confirm New Password"]}
								aria-required="true"
								className="input__text"
								id="confirmPassword"
								name="confirmPassword"
								placeholder=" "
								type="password"
								value={formik.values.confirmPassword || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							/>
							<label htmlFor="confirmPassword" className="input__label">{translations["Confirm New Password"]}</label>
							{(formik.errors.confirmPassword) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="confirmPasswordError"
									aria-live="assertive"
									aria-atomic="true"
								>{formik.errors.confirmPassword}</span>
							) : null}
						</div>
						<div className="input">
							<input
								aria-label={translations.emailMyProfile}
								aria-required="true"
								className="input__text"
								id="email"
								name="email"
								placeholder=" "
								type="text"
								value={formik.values.email || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							/>
							<label htmlFor="email" className="input__label email-label">
								{translations.emailMyProfile}
								<div tabIndex="0" role="tooltip" aria-labelledby="emailInfoP" className="agw-d-inline-flex" data-tooltip="">
									<span className="agw-icon-circle-information agw-align-baseline agw-pe-auto"></span>
									<div className="email-changing-info-wrapper">
										<div className="email-changing-info" style={{ left: "24px" }}>
											<p id="emailInfoP">{translations.ChangingEmail}</p>
										</div>
									</div>
								</div>
							</label>
							{formik.touched.email &&
								(formik.errors.email) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="EmailError"
									aria-live="assertive"
									aria-atomic="true"
								>{formik.errors.email}</span>
							) : null}
						</div>
						<div className="form-inline">
							<div className="input form-inline--60">
								<select
									id="phonePrefixCode"
									name="phonePrefixCode"
									className="input__select"
									value={formik.values.phonePrefixCode || ""}
									onChange={handleInputChange}
									onBlur={formik.handleBlur}
									aria-invalid={!!formik.errors.phonePrefixCode}
								>
									<option value="">{translations.PleaseChoose}</option>
									{phonecodeSelectItems.map((item) => (
										<option key={item.value} value={item.text}>
											{item.value}
										</option>
									))}
								</select>
								<label htmlFor="phonePrefixCode" className="input__label">{translations.countryCodeMyProfile}</label>
								{
									(formik.errors.phonePrefixCode) ? (
										<span
											className="agw-d-block agw-text-negative"
											id="PhonePrefixCodeError"
											aria-live="assertive" aria-atomic="true"
										>{formik.errors.phonePrefixCode}</span>
									) : null}
							</div>
							<div className="input form-inline--40">
								<input
									className="input__text"
									id="phoneNumber"
									name="phoneNumber"
									placeholder=" "
									type="tel"
									value={formik.values.phoneNumber || ""}
									onChange={handleNumberFormat("phoneNumber")}
									onBlur={formik.handleBlur}
									aria-invalid={!!formik.errors.phoneNumber}
								/>
								<label htmlFor="phoneNumber" className="input__label">{translations.phoneSignIn}</label>
								{
									(formik.errors.phoneNumber) ? (
										<span
											className="agw-d-block agw-text-negative"
											id="PhoneNumberError"
											aria-live="assertive" aria-atomic="true"
										>{formik.errors.phoneNumber}</span>
									) : null}
							</div>
						</div>
						<div className="form-inline">
							<div className="input form-inline--60">
								<select
									id="mobilePhonePrefixCode"
									name="mobilePhonePrefixCode"
									className="input__select"
									value={formik.values.mobilePhonePrefixCode || ""}
									onChange={handleInputChange}
									onBlur={formik.handleBlur}
								    aria-invalid={!!formik.errors.mobilePhonePrefixCode}
								>
									<option value="">{translations.PleaseChoose}</option>
									{phonecodeSelectItems.map((item) => (
										<option key={item.value} value={item.text}>
											{item.value}
										</option>
									))}
								</select>
								<label htmlFor="mobilePhonePrefixCode" className="input__label">{translations.countryCodeMyProfile}</label>
								{	
									(formik.errors.mobilePhonePrefixCode) ? (
										<span
											className="agw-d-block agw-text-negative"
											id="MobilePhonePrefixCodeError"
											aria-live="assertive" aria-atomic="true"
										>{formik.errors.mobilePhonePrefixCode}</span>
									) : null}
							</div>
							<div className="input form-inline--40">
								<input
									className="input__text"
									id="mobilePhoneNumber"
									name="mobilePhoneNumber"
									placeholder=" "
									type="text"
									value={formik.values.mobilePhoneNumber || ""}
									onChange={handleNumberFormat("mobilePhoneNumber")}
									onBlur={formik.handleBlur}
									aria-invalid={!!formik.errors.mobilePhoneNumber}
								/>
								<label htmlFor="mobilePhoneNumber" className="input__label">{translations.MobileNumTitle}</label>
								{
									(formik.errors.mobilePhoneNumber) ? (
										<span
											className="agw-d-block agw-text-negative"
											id="MobilePhoneNumberError"
											aria-live="assertive" aria-atomic="true"
										>{formik.errors.mobilePhoneNumber}</span>
									) : null}
							</div>
						</div>
					</fieldset>

					{/* Shipping Information */}
					<fieldset className="form-panel" id="shippingAddressForm">
						<legend className="agw-mb-0">
							<h1 className="agw-fs-title-l">{translations.shippingAddressMyProfile}</h1>
						</legend>
						<div className="input">
							<input
								aria-label={translations.streetMyProfile + " 1"}
								className="input__text"
								id="ShippingAddr_address"
								name="shippingAddr.address"
								placeholder=" "
								type="text"
								value={formik.values.shippingAddr.address || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							/>
							<label htmlFor="ShippingAddr_address" className="input__label">{translations.streetMyProfile} 1</label>
							{(formik.errors.shippingAddr) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="ShippingAddrError"
									aria-live="assertive" aria-atomic="true"
								>{formik.errors.shippingAddr.address}</span>
							) : null}
						</div>
						<div className="input">
							<input
								aria-label={translations.streetMyProfile + " 2"}
								className="input__text"
								id="ShippingAddr_address2"
								name="shippingAddr.address2"
								placeholder=" "
								type="text"
								value={formik.values.shippingAddr.address2 || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							/>
							<label htmlFor="ShippingAddr_address2" className="input__label">{translations.streetMyProfile} 2</label>
							{(formik.errors.shippingAddr) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="ShippingAddr_address2Error"
									aria-live="assertive" aria-atomic="true"
								>{formik.errors.shippingAddr.address2}</span>
							) : null}
						</div>
						<div className="input">
							<input
								aria-label={translations.cityMyProfile}
								className="input__text"
								id="shippingAddr.city"
								name="shippingAddr.city"
								placeholder=" "
								type="text"
								value={formik.values.shippingAddr.city || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							/>
							<label htmlFor="shippingAddr.city" className="input__label">{translations.cityMyProfile}</label>
							{(formik.errors.shippingAddr) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="shippingAddr.cityError"
									aria-live="assertive" aria-atomic="true"
								>{formik.errors.shippingAddr.city}</span>
							) : null}
						</div>
						<div className="form-inline">
							<div className="input form-inline--60">
								<input
									aria-label={translations.stateMyProfile}
									className="input__text"
									id="shippingAddr.state"
									name="shippingAddr.state"
									placeholder=" "
									type="text"
									value={formik.values.shippingAddr.state || ""}
									onChange={handleInputChange}
									onBlur={formik.handleBlur}
								/>
								<label htmlFor="shippingAddr.state" className="input__label">{translations.stateMyProfile}</label>
								{(formik.errors.shippingAddr) ? (
									<span
										className="agw-d-block agw-text-negative"
										id="shippingAddr.stateError"
										aria-live="assertive" aria-atomic="true"
									>{formik.errors.shippingAddr.state}</span>
								) : null}
							</div>
							<div className="input">
								<input
									aria-label={translations.zipMyProfile}
									className="input__text form-inline--40"
									id="shippingAddr.postcode"
									name="shippingAddr.postcode"
									placeholder=" "
									type="text"
									value={formik.values.shippingAddr.postcode || ""}
									onChange={handleInputChange}
									onBlur={formik.handleBlur}
								/>
								<label htmlFor="shippingAddr.postcode" className="input__label">{translations.zipMyProfile}</label>
								{(formik.errors.shippingAddr) ? (
									<span
										className="agw-d-block agw-text-negative"
										id="shippingAddr.postcodeError"
										aria-live="assertive" aria-atomic="true"
									>{formik.errors.shippingAddr.postcode}</span>
								) : null}
							</div>
						</div>
						<div className="input">
							<select
								id="shippingAddr.countryCode"
								name="shippingAddr.countryCode"
								className="input__select"
								aria-label={translations.Region}
								value={formik.values.shippingAddr.countryCode || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							>
								<option value="">{translations.PleaseChoose}</option>
								{countrySelectItems.map((item) => (
									<option key={item.value} value={item.value}>
										{item.text}
									</option>
								))}
							</select>
							<label htmlFor="shippingAddr.countryCode" className="input__label">{translations.Region}</label>
							{(formik.errors.shippingAddr) ? (
								<span
									className="agw-d-block agw-text-negative"
									id="shippingAddr.countryCodeError"
									aria-live="assertive" aria-atomic="true"
								>{formik.errors.shippingAddr.countryCode}</span>
							) : null}
							<div className="auth__note">
								<div className="auth__note-text">
									<p className="field-validation-error" id="shipping-error-msg"></p>
								</div>
							</div>
						</div>
					</fieldset>

					{/* <!-- Billing Information --> */}
					<fieldset className="form-panel" id="billingAddressForm">
						<legend className="agw-mb-0">
							<h1 className="agw-fs-title-l">{translations.billingHeadlineMyProfile}</h1>
						</legend>
						<div className="graphical-input">
							<input
								type="checkbox"
								className="graphical-input__input"
								name="billingFormToggle"
								id="billingFormToggle"
								checked={billingFormCheck}
								onChange={handlebillingFormCheck}
								disabled={billingFormDisabled}
							/>
							<label className="graphical-input__label agw-pe-auto" htmlFor="billingFormToggle">{translations.useShippingAddressCheckbox}</label>
						</div>
						<div id="billingForm" className="is-visible">
							<div className="input">
								<input
									aria-label={translations.streetMyProfile + " 1"}
									className="input__text"
									id="billingAddr.address"
									name="billingAddr.address"
									placeholder=" "
									type="text"
									value={formik.values.billingAddr.address || ""}
									onChange={handleInputChange}
									onBlur={formik.handleBlur}
								/>
								<label htmlFor="billingAddr.address" className="input__label">{translations.streetMyProfile} 1</label>
								{(formik.errors.billingAddr) ? (
									<span
										className="agw-d-block agw-text-negative"
										id="billingAddr.addressError"
										aria-live="assertive" aria-atomic="true"
									>{formik.errors.billingAddr.address}</span>
								) : null}
							</div>
							<div className="input">
								<input
									aria-label={translations.streetMyProfile + " 2"}
									className="input__text"
									id="billingAddr.address2"
									name="billingAddr.address2"
									placeholder=" "
									type="text"
									value={formik.values.billingAddr.address2 || ""}
									onChange={handleInputChange}
									onBlur={formik.handleBlur}
								/>
								<label htmlFor="billingAddr.address2" className="input__label">{translations.streetMyProfile} 2</label>
								{(formik.errors.billingAddr) ? (
									<span
										className="agw-d-block agw-text-negative"
										id="billingAddr.address2Error"
										aria-live="assertive" aria-atomic="true"
									>{formik.errors.billingAddr.address2}</span>
								) : null}
							</div>
							<div className="input">
								<input
									aria-label={translations.cityMyProfile}
									className="input__text"
									id="billingAddr.city"
									name="billingAddr.city"
									placeholder=" "
									type="text"
									value={formik.values.billingAddr.city || ""}
									onChange={handleInputChange}
									onBlur={formik.handleBlur}
								/>
								<label htmlFor="billingAddr.city" className="input__label">{translations.cityMyProfile}</label>
								{(formik.errors.billingAddr) ? (
									<span
										className="agw-d-block agw-text-negative"
										id="billingAddr.cityError"
										aria-live="assertive" aria-atomic="true"
									>{formik.errors.billingAddr.city}</span>
								) : null}
							</div>
							<div className="form-inline">
								<div className="input form-inline--60">
									<input
										aria-label={translations.stateMyProfile}
										className="input__text"
										id="billingAddr.state"
										name="billingAddr.state"
										placeholder=" "
										type="text"
										value={formik.values.billingAddr.state || ""}
										onChange={handleInputChange}
										onBlur={formik.handleBlur}
									/>
									<label htmlFor="billingAddr.state" className="input__label">{translations.stateMyProfile}</label>
									{(formik.errors.billingAddr) ? (
										<span
											className="agw-d-block agw-text-negative"
											id="billingAddr.stateError"
											aria-live="assertive" aria-atomic="true"
										>{formik.errors.billingAddr.state}</span>
									) : null}
								</div>
								<div className="input">
									<input
										aria-label={translations.zipMyProfile}
										className="input__text form-inline--40"
										id="billingAddr.postcode"
										name="billingAddr.postcode"
										placeholder=" "
										type="text"
										value={formik.values.billingAddr.postcode || ""}
										onChange={handleInputChange}
										onBlur={formik.handleBlur}
									/>
									<label htmlFor="billingAddr.postcode" className="input__label">{translations.zipMyProfile}</label>
									{(formik.errors.billingAddr) ? (
										<span
											className="agw-d-block agw-text-negative"
											id="billingAddr.postcodeError"
											aria-live="assertive" aria-atomic="true"
										>{formik.errors.billingAddr.postcode}</span>
									) : null}
								</div>
							</div>
							<div className="input">
								<select
									id="billingAddr.countryCode"
									name="billingAddr.countryCode"
									className="input__select"
									aria-label={translations.Region}
									value={formik.values.billingAddr.countryCode || ""}
									onChange={handleInputChange}
									onBlur={formik.handleBlur}
								>
									<option value="">{translations.PleaseChoose}</option>
									{countrySelectItems.map((item) => (
										<option key={item.value} value={item.value}>
											{item.text}
										</option>
									))}
								</select>
								<label htmlFor="billingAddr.countryCode" className="input__label">{translations.Region}</label>
								{(formik.errors.billingAddr) ? (
									<span
										className="agw-d-block agw-text-negative"
										id="billingAddr.countryCodeError"
										aria-live="assertive" aria-atomic="true"
									>{formik.errors.billingAddr.countryCode}</span>
								) : null}
							</div>
						</div>
					</fieldset>

					{/* Preferences */}
					<fieldset className="form-panel">
						<legend className="agw-mb-0">
							<h1 className="agw-fs-title-l">{translations.preferencesHeadlineMyProfile}</h1>
						</legend>
						<div className="input">
							<select
								id="languageCode"
								name="languageCode"
								className="input__select"
								aria-label={translations.languageMyProfile}
								aria-required="true"
								value={formik.values.languageCode || ""}
								onChange={handleInputChange}
								onBlur={formik.handleBlur}
							>
								<option value="">{translations.PleaseChoose}</option>
								{languageSelectItems.map((item) => (
									<option key={item.value} value={item.value}>
										{item.text}
									</option>
								))}
							</select>
							<label htmlFor="languageCode" className="input__label">{translations.languageMyProfile}</label>
							{/* <span id="Language_Error" className="field-validation-error"></span> */}
							{formik.touched.languageCode &&
								(formik.errors.languageCode) ? (
								<span
									className="agw-d-block agw-text-negative"
										id="languageCodeError"
									aria-live="assertive"
									aria-atomic="true"
									>{formik.errors.languageCode}</span>
							) : null}
						</div>
						<div className="graphical-input">
							<input
								className="graphical-input__input"
								type="checkbox"
								name="contactAgreement"
								id="contactAgreement"
								checked={formik.values.contactAgreement || false}
								onChange={formik.handleChange}
							/>
							<label className="graphical-input__label agw-pe-auto" htmlFor="contactAgreement">{translations.KeepInfo}</label>
							<span className="field-validation-error" id="contactAgreement_Error"></span>
						</div>
					</fieldset>

					{/* <!--save changes--> */}
					{isSaveBoxVisible && (
						<div className="form__save-changes--bar" style={{ height: isSaveBoxVisible ? "120px" : "0px" }}>
							<div className={`form__save-changes--buttons ${isSaveBoxVisible ? 'agw-d-block' : 'agw-d-none'}`}>
								<button id="btn-cancel" type="button" className="btn" onClick={handleCancel}>Cancel</button>
								<button id="btn-save" type="submit" className="btn btn--primary" ref={triggerButtonRef}>{translations.saveChangesMyProfile}</button>
							</div>
						</div>
					)}

					<div className="auth__note">
						<div className="auth__note-text">
							<p className="field-validation-error" id="auth-error-msg" role="status"></p>
						</div>
					</div>

					<div className="auth__note">
						<div className="auth__note-text">
							<p id="auth-success-msg" className="field-success agw-bg-positive agw-text-100" role="status">{successMsg}</p>
						</div>
					</div>
				</form>
			</div>
			<ActiveLightbox
				activeLightbox={activeLightbox}
				email={formik.values.email}
				onClose={closeAllLightboxes}
				onOpenLightbox={openLightbox}
				triggerButtonRef={triggerButtonRef}
				isFromCCDB={true}
			/>
		</>
	);
}

export default MyProfile;