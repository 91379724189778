export async function fetchApi(url, method = "GET", data = null) {
  const options = {
    method: method,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  const response = await fetch(url, options);
  const responseData = await response.json();

  return responseData;
}

export function getCookie(name) {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : null;
}


export function isNumber(value) {
  return isFinite(Number(value));
}

export const replaceStrToNum = (str, text) => {
  if (!str) return str;

  return str.replace("{0}", text);
};


export function getQueryParamIgnoreCase(queryParams, name) {
  for (const [key, value] of queryParams.entries()) {
    if (key.toLowerCase() === name.toLowerCase()) {
      return value;
    }
  }
  return null;
}

export function Base64ToUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str.replace(/-/g, '+').replace(/_/g, '/')), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

// GTM function
export function handleGTM(e, event, eventAction, eventLabel, eventParam1, click_url) {
  e.stopPropagation();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'event': event, 'eventAction': eventAction, 'eventLabel': eventLabel, 'eventParam1': eventParam1, 'click_url': click_url });
}


export function startOTPCountdown(durationInSeconds, onTick, onComplete) {
  const endTime = Date.now() + durationInSeconds * 1000;

  const updateRemainingTime = () => {
    const currentTime = Date.now();
    const timeLeft = Math.max(0, Math.floor((endTime - currentTime) / 1000));

    if (onTick) onTick(timeLeft);

    if (timeLeft === 0) {
      clearInterval(intervalId);
      if (onComplete) onComplete();
    }
  };

  const intervalId = setInterval(updateRemainingTime, 1000);
  updateRemainingTime(); 

  return () => clearInterval(intervalId);
}

export function formatOTPTime(time) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return minutes > 0
    ? `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
    : seconds.toString().padStart(2, "0");
}

