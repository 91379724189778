import React, { useContext } from "react";
import { TranslationContext } from "contexts/TranslationProvider";

function MediaBtn({ state, codeChallenge }) {
  const translations = useContext(TranslationContext);
  const handleSocialLogin = (social) => {
    const url = `${process.env.REACT_APP_IDPHUB}/oauth2/authorize?redirect_uri=${process.env.REACT_APP_URL}/account/callback&response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&identity_provider=${social}&scope=aws.cognito.signin.user.admin email openid phone profile&state=${state}`;
    window.location.href = url;
  };

  return (
    <div className="agw-col-md-5 agw-mx-auto">
      <div className="agw-row agw-gy-150">
        <div className="agw-col-12">
          <button
            className="agw-btn agw-btn-block agw-d-flex agw-align-items-center agw-border agw-border-gray-600"
            onClick={() => handleSocialLogin("Google")}
            role="link"
          >
            <div className="agw-icon-google-color agw-fs-title-s agw-me-100">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </div>
            <span className="agw-fs-body-m agw-text-gray-600 agw-fw-semibold">
              {translations.GoogleSocialMediaText}
            </span>
          </button>
        </div>
        <div className="agw-col-12">
          <button
            className="agw-btn agw-btn-block agw-d-flex agw-align-items-center agw-border agw-border-gray-600"
            onClick={() => handleSocialLogin("Microsoft")}
            role="link"
          >
            <div className="agw-icon-windows-color agw-fs-title-s agw-me-100">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </div>
            <span className="agw-fs-body-m agw-text-gray-600 agw-fw-semibold">
              {translations.MicrosoftSocialMediaText}
            </span>
          </button>
        </div>
        <div className="agw-col-12">
          <button
            className="agw-btn agw-btn-block agw-d-flex agw-align-items-center"
            style={{ backgroundColor: "#4267B2" }}
            onClick={() => handleSocialLogin("Facebook")}
            role="link"
          >
            <span className="agw-icon-facebook agw-text-white agw-fs-title-s agw-me-100"></span>
            <span className="agw-fs-body-m agw-text-white agw-fw-semibold">
              {translations.FacebookSocialMediaText}
            </span>
          </button>
        </div>
        <div className="agw-col-12">
          <button
            className="agw-btn agw-btn-block agw-d-flex agw-align-items-center agw-bg-black"
            onClick={() => handleSocialLogin("Twitter")}
            role="link"
          >
            <span className="agw-icon-twitter-x agw-text-white agw-fs-title-s agw-me-100"></span>
            <span className="agw-fs-body-m agw-text-white agw-fw-semibold">
              {translations.TwitterSocialMediaText}
            </span>
          </button>
        </div>
        <div className="agw-col-12">
          <button
            className="agw-btn agw-btn-block agw-d-flex agw-align-items-center agw-bg-black"
            onClick={() => handleSocialLogin("SignInWithApple")}
            role="link"
          >
            <span className="agw-icon-apple agw-text-white agw-fs-title-s agw-me-100"></span>
            <span className="agw-fs-body-m agw-text-white agw-fw-semibold">
              {translations.AppleSocialMediaText}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default MediaBtn;
