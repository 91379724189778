import React, { useContext, useState, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { TranslationContext } from 'contexts/TranslationProvider';
import * as utils from "../helpers/utilityHelpers";

function ProspectActv() {
    const locale = useParams().locale;
    const location = useLocation();
    const translations = useContext(TranslationContext);
    const queryParams = new URLSearchParams(location.search);
    const validationToken = utils.getQueryParamIgnoreCase(queryParams, "validationToken") ?? "";
    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${process.env.REACT_APP_URL}/${locale}/api/account/prospectactv?validationToken=${validationToken}`;
                const res = await utils.fetchApi(url);
            }
            catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [locale]);

    return (
        <div className="agw-pt-6">
            <div className="agw-col-md-6 agw-mx-auto agw-text-center" dangerouslySetInnerHTML={{ __html: translations.PropsectWelcomePage }}></div>
        </div>
    )
}

export default ProspectActv;