import { React, useContext } from "react";
import { useParams } from "react-router-dom";
import { TranslationContext } from "contexts/TranslationProvider";
import * as utils from "../helpers/utilityHelpers";
import { useLoading } from "../contexts/LoadingProvider";

const DeleteProduct = ({ isOpen, onClose, Model, Sn }) => {
  const locale = useParams().locale;
  const translations = useContext(TranslationContext);
  const { isLoading, setIsLoading } = useLoading();

  if (!isOpen) return null;

  // delete product
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_URL}/${locale}/api/account/delete-product?sn=${Sn}`;
      const res = await utils.fetchApi(url);

      if (res.redirectUrl) {
        window.location.href = res.redirectUrl;
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={`${isOpen ? "" : "agw-d-none"}`}>
      <div id="RemovePopUp" className="pop-up__container" data-pop-toggle="" role="dialog" aria-describedby="MakesureRemoveP" aria-label="TMP414RN-53">
        <div className="pop-up__box" id="RemovePopUpWhiteSection">
          <button className="pop-up__close agw-btn" tabIndex="0" aria-label={translations.close} onClick={onClose}>
            <span className="agw-icon-x-mark"></span>
          </button>
          <div className="pop-up__content">
            <div className="pop-up__warning">
              <span className="agw-icon-circle-exclamation" style={{ fontSize: "64px" }}></span>
            </div>
            <div className="pop-up__text">
              <p id="MakesureRemoveP" dangerouslySetInnerHTML={{ __html: translations.MakesureRemove }} />
              <div>
                <h2 id="RemoveModel" className="agw-fs-title-m">{Model}</h2>
                <p id="RemoveId">{Sn}</p>
                <input type="hidden" id="RemoveIdValue" value={Sn} />
              </div>
            </div>
            <div className="pop-up__button">
              <button className="agw-btn pop-up__remove" id="PopUpRemove" aria-label={translations.Remove} onClick={handleSubmit}>{translations.Remove}</button>
              <button className="agw-btn pop-up__cancel" id="PopUpCancel" aria-label={translations.Cancel} onClick={onClose}>{translations.Cancel}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteProduct;