import { React, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TranslationContext } from "contexts/TranslationProvider";
import * as validationSchemas from "../validation/validationSchemas";
import * as utils from "../helpers/utilityHelpers";
import { useFormik } from "formik";
import { useLoading } from "../contexts/LoadingProvider";
import LeftMenu from "components/LeftMenu";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function RegisterProducts() {
  const locale = useParams().locale;
  const translations = useContext(TranslationContext);
  const { isLoading, setIsLoading } = useLoading();
  const [errorMsg, setErrorMsg] = useState("");
  document.title = "Register a Product | " + translations.siteName;

  useEffect(() => {
		setIsLoading(true);
		const fetchSelectItems = async () => {
			try {
				const url = `${process.env.REACT_APP_URL}/${locale}/api/account/register-product`;
				const res = await utils.fetchApi(url);

				if (res.redirectUrl) {
					window.location.href = res.redirectUrl;
        }
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchSelectItems();
	}, []);




  const formik = useFormik({
    initialValues: {
      SerialNumber: "",
      PurchaseDate: new Date(),
      Usage: "",
      submitButtonId: ""
    },
    validationSchema: validationSchemas.registerProductValidationSchema(translations),
    validateOnBlur: false,
    onSubmit: async (values) => {
      setErrorMsg("");
      setIsLoading(true);
      // console.log(values);
      try {
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/register-product`;
        const res = await utils.fetchApi(url, "POST", values);
        if (res.success) {
          if (res.redirectUrl) {
            window.location.href = res.redirectUrl;
          }
        }
        else {
          setErrorMsg(res.errorMsg);
          let errorInput = null;
          res.errors.forEach(async (error) => {
            await formik.setFieldError(error.id, error.errorMsg);
            if (errorInput === null) {
              errorInput = document.getElementsByName(error.id)[0];
              errorInput.focus();
            }
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleResetForm = () => {
    formik.resetForm();
    window.location.href = `${process.env.REACT_APP_URL}/${locale}/account/my-products`;
  }

  return (
    <>
      <LeftMenu />
      <div className="account__content">
        <form id="main-form" onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}>
          <input name="__RequestVerificationToken" type="hidden" value="G8LBa7nod1hTkA0j4Rv_gVf-EFX8xXC8JcZE9IrQpTbrpAfe2SBIO6XE0IycXCSaXC_olLN_DHiu0CVEk8d3C05BPeoXdCFGOeto5Mmgg3k1" />
          <fieldset className="form-panel">
            <legend className="agw-mb-0">
              <h1 className="agw-fs-title-l">{translations["Register a Product"]}</h1>
            </legend>

            <div className="input">
              <input
                aria-required="true"
                className="input__text"
                id="SerialNumber"
                name="SerialNumber"
                type="text"
                aria-label={translations.SerialOrSNID}
                value={formik.values.SerialNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="SerialNumber" className="input__label">{translations.SerialOrSNID}</label>
              {formik.touched.SerialNumber &&
                (formik.errors.SerialNumber) ? (
                <span
                  className="agw-d-block field-validation-error"
                  id="SerialNumberError"
                  aria-live="assertive" aria-atomic="true"
                  dangerouslySetInnerHTML={{ __html: formik.errors.SerialNumber }}
                ></span>
              ) : null}
            </div>

            <div className="register__product--item">
              <a
                className="register__find-serial-link agw-d-flex agw-align-items-center agw-text-decoration-none"
                target="_blank"
                href="https://community.acer.com/en/kb/articles/57-locating-your-acer-snid-or-serial-number"
                onClick={(e) => utils.handleGTM(e, 'ct_register product', `${translations.HowDoIFindSN}`, 'register product', `${translations.HowDoIFindSN}`, e.view.click_url.href)}
              >
                <span className="agw-icon-circle-exclamation agw-mr-1" aria-hidden="true"></span>
                <span style={{ textDecoration: 'underline' }}>{translations.HowDoIFindSN}</span>
              </a>
            </div>

            <div className="register__product--item">
              <div className="register__product__item--purchase-date">
                <div className="input ">
                  <div className="date-picker agw-d-flex">
                    <DatePicker
                      className="input__text hasDatepicker agw-px-050"
                      id="PurchaseDate"
                      showIcon
                      toggleCalendarOnIconClick
                      selected={formik.values.PurchaseDate}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => formik.setFieldValue('PurchaseDate', date)}
                      maxDate={new Date()}
                      aria-label={translations["Purchase Date"]}
                      icon={<span className="agw-icon-calendar agw-po-absolute agw-right-0" id="CalendarIcon" style={{ fontSize: "24px" }}></span>}
                    />
                  </div>
                  <label htmlFor="PurchaseDate" className="input__label" id="purchaseDateLabel">{translations["Purchase Date"]}</label>
                </div>
              </div>
              <div>
                {formik.touched.PurchaseDate &&
                  (formik.errors.PurchaseDate) ? (
                  <span
                    className="agw-d-block agw-text-negative"
                    id="PurchaseDateError"
                    aria-live="assertive" aria-atomic="true"
                  >{formik.errors.PurchaseDate}</span>
                ) : null}
              </div>
            </div>

            <div className="input">
              <select
                id="Usage"
                name="Usage"
                className="input__select"
                aria-required="true"
                aria-label={translations.Usage}
                value={formik.values.Usage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="" defaultValue>{translations.PleaseSelect}</option>
                <option value="Home Entertainment">{translations["Home Entertainment"]}</option>
                <option value="Business">{translations.Business}</option>
              </select>
              <label htmlFor="Usage" className="input__label">{translations.Usage}</label>
              {formik.touched.Usage &&
                (formik.errors.Usage) ? (
                <span
                  className="agw-d-block agw-text-negative"
                  id="UsageError"
                  aria-live="assertive" aria-atomic="true"
                >{formik.errors.Usage}</span>
              ) : null}
            </div>

            <div className="register__product--footer agw-btn-group">
              <button
                className="agw-btn agw-btn-tertiary-black"
                id="btn-cancel"
                type="reset"
                onClick={handleResetForm}
              >
                {translations.Cancel}
              </button>
              <button
                className="agw-btn agw-btn-primary"
                id="btn-save"
                type="submit"
              >
                {translations.Save}
              </button>
            </div>

            <div className="auth__note">
              <p id="auth-error-msg" className="field-validation-error"></p>
            </div>

            <div className="auth__note">
              <p id="auth-success-msg" className="field-success"></p>
            </div>
          </fieldset>
        </form>
      </div>

    </>

  )

}

export default RegisterProducts;