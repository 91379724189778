import {React, useState, useEffect} from "react";
import { useLocation, useParams } from "react-router-dom";
import { TranslationProvider } from "./contexts/TranslationProvider";
import { LoadingProvider } from "./contexts/LoadingProvider";
import { PrevLinkProvider } from "./contexts/PrevLinkProvider";
import Loading from "./components/Loading";
import Header from "components/Header";
import AGWHeader from "components/AGWHeader";
import Footer from "components/Footer";
import AGWFooter from "components/AGWFooter";


function Layout({ children }) {
  const { locale } = useParams();
  const location = useLocation();
  const targetPath = ['/my-profile', '/my-products', '/register-product'];
  const noDisplayHeaderFooter = ['/email-verify'];
  const [header, setHeader] = useState("");
  const [footer, setFooter] = useState("");
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [isnoDisplayHeaderFooter, setIsnoDisplayHeaderFooter] = useState(false);

  useEffect(() => {

    const fetchLayout = async () => {
      try {
        const isAftrSignInPage = targetPath.some((path) => location.pathname.includes(path));
        setIsMyProfile(isAftrSignInPage);     
        setHeader(isAftrSignInPage ? <AGWHeader /> : <Header />); 
        setFooter(isAftrSignInPage ? <AGWFooter /> : <Footer />);
        // await new Promise(resolve => setTimeout(resolve, 800));
        
        setIsnoDisplayHeaderFooter(noDisplayHeaderFooter.some((path) => location.pathname.includes(path)));


      } catch (error) {
        console.error(error);
      }
    };

    fetchLayout();
  }, []);

  return (
    <LoadingProvider>
      <PrevLinkProvider>
        <TranslationProvider locale={locale}>
          {!isnoDisplayHeaderFooter && header}
          {header && footer && (
            <main role="main" className={`agw-container agw-min-vh-60 ${isMyProfile ? 'account acer' : ''}`} id="main">
              {isMyProfile ? children : (
                <div className="agw-pb-400 agw-row agw-flex-column">
                  {children}
                </div>
              )}
            </main>
          )}
          {!isnoDisplayHeaderFooter && footer}
          <Loading />
        </TranslationProvider>
      </PrevLinkProvider>
    </LoadingProvider>
  );
}

export default Layout;
