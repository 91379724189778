import React, { useContext } from "react";
import { TranslationContext } from "../contexts/TranslationProvider";

function ErrorPage() {
  const translations = useContext(TranslationContext);

  return (
    <div className="agw-my-300 agw-text-center" aria-live="assertive" aria-atomic="true">
      <h1 className="agw-fs-headline-s agw-text-acer-primary">
        Oops！
      </h1>
      <p className="agw-fs-title-s agw-mb-150">
        The page you're looking for can't be found.
      </p>
    </div>
  );
}

export default ErrorPage;
