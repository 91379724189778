import { React, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { TranslationContext } from "contexts/TranslationProvider";
import * as utils from "../helpers/utilityHelpers";

function LeftMenu() {
  const locale = useParams().locale;
  const translations = useContext(TranslationContext);



  return (
    <>
      <div className="account__nav--mobile">
        <a tabIndex="0" className="slideout-trigger" role="button" aria-label={translations.AccountOption} data-open="panel" data-target="#slideout-left-rail">
          <span className="agw-icon-kebab-menu" style={{ fontSize: "28px" }}></span>
          {translations.AccountOption}
        </a>
      </div>
      <aside className="account__nav">
        <div className="slideout slideout--inline" id="slideout-left-rail">
          <div className="slideout__inner slideout__inner--inline">
            <a href="#" className="mobile-menu-btn slideout-trigger" role="button" aria-label={translations.close} data-open="panel" data-target="#slideout-left-rail">
              <div className="hb hb--icon4 m-3">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <ul className="account__menu">
              <li className={`account__menu-item ${window.location.pathname.includes('/my-profile') ? 'is-active' : ''}`}>
                <Link className="account__menu-link" aria-label={translations["My Profile"]} to={`/${locale}/account/my-profile`} target="_self">
                  <div className="agw-px-150 agw-py-050" aira-hidden="true">
                    <span className="agw-icon-profile agw-lh-1" style={{ fontSize: "40px" }}></span>
                  </div>
                  <span>{translations["My Profile"]}</span>
                </Link>
              </li>
              <li className={`account__menu-item ${window.location.pathname.includes('/my-products') ? 'is-active' : ''}`}>
                <Link className="account__menu-link" aria-label={translations["My Products"]} to={`/${locale}/account/my-products`} target="_self">
                  <div className="agw-px-150 agw-py-050" aira-hidden="true">
                    <span className="agw-icon-laptop agw-lh-1" style={{ fontSize: "40px" }}></span>
                  </div>
                  <span>{translations["My Products"]}</span>
                </Link>
              </li>
              <li className={`account__menu-item ${window.location.pathname.includes('/register-product') ? 'is-active' : ''}`}>
                <Link className="account__menu-link" aria-label={translations["Register a Product"]} to={`/${locale}/account/register-product`} target="_self">
                  <div className="agw-px-150 agw-py-050" aira-hidden="true">
                    <span className="agw-icon-laptop-check agw-lh-1" style={{ fontSize: "40px" }}></span>
                  </div>
                  <span>{translations["Register a Product"]}</span>
                </Link>
              </li>
              <li className="account__menu-item">
                <Link className="account__menu-link" aria-label={translations["My Community"]} target="_blank" to="https://community.acer.com">
                  <div className="agw-px-150 agw-py-050" aira-hidden="true">
                    <span className="agw-icon-people agw-lh-1" style={{ fontSize: "40px" }}></span>
                  </div>
                  <span>{translations["My Community"]}</span>
                </Link>
              </li>
              <li className="account__menu-item">
                <a className="account__menu-link" aria-label={translations["Sign Out"]} href={`/${locale}/account/logout`} target="_self" onClick={(e) => utils.handleGTM(e, 'pt_sign_out', 'signout_success')}>
                  <div className="agw-px-150 agw-py-050" aira-hidden="true">
                    <span className="agw-icon-leave agw-lh-1" style={{ fontSize: "40px" }}></span>
                  </div>
                  <span>{translations["Sign Out"]}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
}

export default LeftMenu;