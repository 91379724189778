import * as utils from "./utilityHelpers";

export const noHtmlTagRegex = /^[^<>]*$/;

export async function checkEmailExists(email, locale, translations) {
  try {
    const url = `${process.env.REACT_APP_URL}/${locale}/api/account/check-account`;
    const res = await utils.fetchApi(url, "POST", {
      email: email,
    });
    if (res.success) {
      return { success: true };
    } else {
      if (res.errorMsg) {
        return { success: false, error: res.errorMsg };
      }
      return {
        success: false,
        error: utils.replaceStrToNum(translations.EmailUsed, locale),
      };
    }
  } catch (error) {
    return { success: false, error: translations.InternetError };
  }
}
