import React, { useState, useContext, Fragment, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useLoading } from "../contexts/LoadingProvider";
import { TranslationContext } from "contexts/TranslationProvider";
import * as utils from "../helpers/utilityHelpers";
import * as validationSchemas from "../validation/validationSchemas";
import { usePWshow } from "hooks/useCustom";
import { useFormik } from "formik";

function UpdatePassword() {
  const locale = useParams().locale;
  const navigate = useNavigate();
  const location = useLocation();
  const translations = useContext(TranslationContext);
  const [isPwShow, isConfirmPwShow, handlePwShow, handleConfirmPwShow] =
    usePWshow(false);
  const { isLoading, setIsLoading } = useLoading();
  const [resetErrorMsg, setResetErrorMsg] = useState("");
  const token = window.location.href.split("=")[1];

  useEffect(() => {
    const fetchSelectItems = async () => {
      try {
        setIsLoading(true);
        const queryParams = new URLSearchParams(location.search.toLowerCase());
        const token = queryParams.get("password_token") ?? "";
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/update-password?token=${token}`;
        const res = await utils.fetchApi(url);

        if (res.redirectUrl) {
          window.location.href = res.redirectUrl;
        }
        else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchSelectItems();
  }, []);



  const formik = useFormik({
    initialValues: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    validationSchema:
      validationSchemas.updatePasswordValidationSchema(translations),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      const postData = { ...values, Code: token };

      try {
        const postUrl = `${process.env.REACT_APP_URL}/${locale}/api/account/update-password`;
        const res = await utils.fetchApi(postUrl, "POST", postData);
        if (res.success) {
          navigate(`/${locale}/account/update-password-complete`);
        } else {
          setResetErrorMsg(res.errorMsg);
          res.errors.forEach(async (error) => {
            await formik.setFieldError(`${error.id}`, error.errorMsg);
            const errorInput = document.getElementsByName(`${error.id}`)[0];
            if (errorInput) {
              errorInput.focus();
            }
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <Fragment>
      <div className="agw-col-md-5 agw-mx-auto agw-mb-300">
        <h1 className="agw-fs-headline-s agw-text-center agw-mb-150">
          {translations.UpdatePassword}
        </h1>
      </div>

      <form className="agw-col-md-5 agw-mx-auto" onSubmit={formik.handleSubmit}>
        <div className="agw-row agw-gy-150">
          <div className="agw-col-12">
            <label
              htmlFor="NewPassword"
              className="agw-form-label agw-fw-semibold"
            >
              {translations["New Password"]}{" "}
              <span className="agw-text-negative">*</span>
            </label>
            <div className="agw-po-relative">
              <input
                type={isPwShow ? "text" : "password"}
                className={`agw-form-control ${formik.touched.NewPassword && formik.errors.NewPassword
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="NewPassword"
                name="NewPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.NewPassword}
                aria-invalid="true"
                aria-required="true"
              />
              <button
                className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pe-100"
                style={{ transform: "translate(-100%,-50%)" }}
                type="button"
                onClick={(e) => handlePwShow(e)}
              >
                <span className={`agw-icon-visibility-off agw-fs-title-m agw-pe-none ${isPwShow ? "agw-d-none" : ""}`}></span>
                <span className={`agw-icon-visibility agw-fs-title-m agw-pe-none ${isPwShow ? "" : "agw-d-none"}`}></span>
              </button>
            </div>
            {formik.touched.NewPassword && formik.errors.NewPassword ? (
              <span
                dangerouslySetInnerHTML={{ __html: formik.errors.NewPassword }}
                className="agw-d-block agw-mt-050 agw-text-negative"
              ></span>
            ) : null}
          </div>
          <div className="agw-col-12">
            <label
              htmlFor="ConfirmNewPassword"
              className="agw-form-label agw-fw-semibold"
            >
              {translations.ConfirmPassword}{" "}
              <span className="agw-text-negative">*</span>
            </label>
            <div className="agw-po-relative">
              <input
                type={isConfirmPwShow ? "text" : "password"}
                className={`agw-form-control ${formik.touched.ConfirmPassword &&
                  formik.errors.ConfirmPassword
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="ConfirmNewPassword"
                name="ConfirmPassword"
                value={formik.values.ConfirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                aria-invalid="true"
                aria-required="true"
              />
              <button
                className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pe-100"
                style={{ transform: "translate(-100%,-50%)" }}
                type="button"
                onClick={(e) => handleConfirmPwShow(e)}
              >
                <span className={`agw-icon-visibility-off agw-fs-title-m agw-pe-none ${isConfirmPwShow ? "agw-d-none" : ""}`}></span>
                <span className={`agw-icon-visibility agw-fs-title-m agw-pe-none ${isConfirmPwShow ? "" : "agw-d-none"}`}></span>
              </button>
            </div>
            {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: formik.errors.ConfirmPassword,
                }}
                className="agw-d-block agw-mt-050 agw-text-negative"
              ></span>
            ) : null}
          </div>
          <div className="agw-col-12 agw-gy-300">
            <p className="agw-text-negative agw-text-center">{resetErrorMsg}</p>
            <button
              type="submit"
              className="agw-btn agw-btn-block agw-btn-primary"
              disabled={formik.isSubmitting}
            >
              {translations["Change Password"]}
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

export default UpdatePassword;
