import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { LightBoxEnum } from "components/Enum";
import * as utils from "../helpers/utilityHelpers";
import * as validateHelper from "../helpers/validationHelpers";
import { TranslationContext } from "contexts/TranslationProvider";
import Recaptcha from "components/Recaptcha";
import { useRecaptcha } from "hooks/useRecaptcha";
import * as validationSchemas from "../validation/validationSchemas";
import { useFormik } from "formik";
import ActiveLightbox from "../components/ActiveLightbox";
import useLightbox from "../hooks/useLightbox";
import { useLoading } from "../contexts/LoadingProvider";

const Federated = () => {
  const [activeLightbox, openLightbox, closeAllLightboxes] = useLightbox();
  const locale = useParams().locale;
  const location = useLocation();
  const currentSearch = location.search;
  const translations = useContext(TranslationContext);
  const [headLine, setHeadLine] = useState("");
  const { isLoading, setIsLoading } = useLoading();
  const [isChecked, setIsChecked] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [agreeToCreateAccount, setAgreeToCreateAccount] = useState("");
  const [countrySelectItems, setCountrySelectItems] = useState([]);
  const [languageSelectItems, setLanguageSelectItems] = useState([]);
  const recaptchaRef = React.useRef();
  const triggerButtonRef = useRef(null);
  const { resetRecaptcha } = useRecaptcha(recaptchaRef);

  useEffect(() => {
    setIsLoading(true);
    const fetchCountrySelectItems = async () => {
      try {
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/federated-sign-up`;
        const res = await utils.fetchApi(url);
        const href = window.location.href;
        const hrefEmail = href.split(/&e=|&f=|&l=/)[1];
        const hrefFirstName = href.split(/&e=|&f=|&l=/)[3];
        const hrefLastName = href.split(/&e=|&f=|&l=/)[2];

        setCountrySelectItems(res.countrySelectItems);
        setLanguageSelectItems(res.languageSelectItems);
        setHeadLine(res.headline);
        setAgreeToCreateAccount(res.agreeToCreateAccount);
        formik.setFieldValue("ContactAgreement", res.signupOpinPretick);
        formik.setFieldValue("CountryCode", res.countryCode);
        formik.setFieldValue("LanguageCode", res.languageCode);
        formik.setFieldValue("email", atob(hrefEmail));
        formik.setFieldValue("lastName", await utils.Base64ToUnicode(hrefLastName));
        formik.setFieldValue("firstName", await utils.Base64ToUnicode(hrefFirstName));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCountrySelectItems();
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      CountryCode: "",
      LanguageCode: "",
      ContactAgreement: false,
      Recaptcha: "",
    },
    validationSchema:
      validationSchemas.federatedSignUpValidationSchema(translations),
    onSubmit: async (values, { setSubmitting }) => {
      setErrorMsg("");
      setIsLoading(true);

      try {
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/federated-sign-up`;
        const res = await utils.fetchApi(url, "POST", values);
        if (res.success) {
          window.location.href = res.redirectUrl;
        } else {
          setErrorMsg(res.errors[0].errorMsg);
          resetRecaptcha();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
        setIsLoading(false);
      }
    },
  });


  const handleEmailBlur = async (e) => {
    const res = await validateHelper.checkEmailExists(
      e.target.value,
      locale,
      translations
    );
    if (!res.success) {
      await formik.setTouched({ ...formik.touched, email: true });
      await formik.setFieldError("email", res.error);
    }
  };

  return (
    <Fragment>
      <div className="agw-mb-300">
        <h1 className="agw-fs-headline-s agw-text-center">
          {headLine}
        </h1>
      </div>
      <div className="agw-mb-300 agw-col-md-5 agw-mx-auto">
        <p className="agw-fs-body-l agw-mb-0">
          {translations.alreadyAMember}&nbsp;
          <Link
            className="agw-fw-semibold agw-link-acer agw-text-decoration-underline"
            style={{ cursor: "pointer" }}
            to={`/${locale}/account/sign-in${currentSearch}`}
          >
            {translations.SignIn}
          </Link>
        </p>
      </div>

      <form className="agw-col-md-5 agw-mx-auto" onSubmit={formik.handleSubmit}>
        <div className="agw-row agw-gy-150">
          <div className="agw-col-12">
            <label htmlFor="email" className="agw-form-label agw-fw-semibold">
              {translations.emailAddressRegister}
              <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                *
              </span>
            </label>
            <input
              type="email"
              className="agw-form-control"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={handleEmailBlur}
              value={formik.values.email}
              aria-invalid="true"
              aria-required="true"
            />
            {formik.touched.email && formik.errors.email ? (
              <span
                dangerouslySetInnerHTML={{ __html: formik.errors.email }}
                className="agw-d-block agw-mt-050 agw-text-negative"
              ></span>
            ) : null}
            <span className="agw-d-block agw-mt-050 agw-text-negative">
              {errorMsg}
            </span>
          </div>
          <div className="agw-col-12">
            <label
              htmlFor="firstName"
              className="agw-form-label agw-fw-semibold"
            >
              {translations.firstNameRegister}
              <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                *
              </span>
            </label>
            <input
              type="text"
              className="agw-form-control"
              id="firstName"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              aria-invalid="true"
              aria-required="true"
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <span className="agw-d-block agw-mt-050 agw-text-negative">
                {formik.errors.firstName}
              </span>
            ) : null}
          </div>
          <div className="agw-col-12">
            <label
              htmlFor="lastName"
              className="agw-form-label agw-fw-semibold"
            >
              {translations.lastNameRegister}
              <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                *
              </span>
            </label>
            <input
              type="text"
              className="agw-form-control"
              id="lastName"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              aria-invalid="true"
              aria-required="true"
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <span className="agw-d-block agw-mt-050 agw-text-negative">
                {formik.errors.lastName}
              </span>
            ) : null}
          </div>
          <div className="agw-col-12">
            <label htmlFor="CountryCode" className="agw-form-label">
              {translations.Region}
              <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                *
              </span>
            </label>
            <select
              id="CountryCode"
              className="agw-form-select"
              name="CountryCode"
              value={formik.values.CountryCode}
              onChange={formik.handleChange}
              aria-invalid="true"
              required
            >
              {countrySelectItems.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
            {formik.touched.CountryCode && formik.errors.CountryCode ? (
              <span className="agw-d-block agw-mt-050 agw-text-negative">
                {formik.errors.CountryCode}
              </span>
            ) : null}
          </div>
          <div className="agw-col-12">
            <label htmlFor="LanguageCode" className="agw-form-label">
              {translations.languageMyProfile}
              <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                *
              </span>
            </label>
            <select
              id="LanguageCode"
              className="agw-form-select"
              name="LanguageCode"
              value={formik.values.LanguageCode}
              onChange={formik.handleChange}
              aria-invalid="true"
              required
            >
              {languageSelectItems.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
            {formik.touched.LanguageCode && formik.errors.LanguageCode ? (
              <span className="agw-d-block agw-mt-050 agw-text-negative">
                {formik.errors.LanguageCode}
              </span>
            ) : null}
          </div>
          <div className="agw-col-12 agw-gy-300">
            <Recaptcha
              recaptchaRef={recaptchaRef}
              formik={formik}
            />
            {formik.touched.Recaptcha && formik.errors.Recaptcha ? (
              <span className="agw-d-block agw-mt-050 agw-text-center agw-text-negative">
                {formik.errors.Recaptcha}
              </span>
            ) : null}
          </div>
          <div className="agw-col-12 agw-gy-300">
            <div className="agw-form-check">
              <input
                type="checkbox"
                className="agw-form-check-input"
                id="ConsumerPolicyCheckbox"
                checked={isChecked}
                onChange={() => setIsChecked((Checked) => !Checked)}
              />
              <label
                className="agw-form-check-label"
                htmlFor="ConsumerPolicyCheckbox"
                dangerouslySetInnerHTML={{
                  __html: utils.replaceStrToNum(agreeToCreateAccount, locale),
                }}
              ></label>
            </div>
          </div>
          <div className="agw-col-12">
            <div className="agw-form-check">
              <input
                type="checkbox"
                className="agw-form-check-input"
                id="ConsumerAgreeCheckbox"
                name="ContactAgreement"
                checked={formik.values.ContactAgreement}
                onChange={formik.handleChange}
              />
              <label
                className="agw-form-check-label"
                htmlFor="ConsumerAgreeCheckbox"
              >
                {translations.agreeToSubscribe}
              </label>
            </div>
          </div>
          <div className="agw-col-12 agw-gy-300">
            {errorMsg ? (
              <p className="agw-text-negative agw-text-center">{errorMsg}</p>
            ) : null}
            <button
              type="submit"
              className="agw-btn agw-btn-block agw-btn-primary"
              disabled={!formik.isSubmitting && !isChecked}
              ref={triggerButtonRef}
            >
              {translations.SignUp}
            </button>
          </div>
        </div>
      </form>
      <ActiveLightbox
        activeLightbox={activeLightbox}
        email={formik.values.email}
        onClose={closeAllLightboxes}
        onOpenLightbox={openLightbox}
        triggerButtonRef={triggerButtonRef}
      />
    </Fragment>
  );
};

export default Federated;
