import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import MediaBtn from "components/MediaBtn";
import { LightBoxEnum } from "components/Enum";
import { usePWshow } from "../hooks/useCustom";
import * as utils from "../helpers/utilityHelpers";
import * as validateHelper from "../helpers/validationHelpers";
import { TranslationContext } from "contexts/TranslationProvider";
import HeadlineContext from "contexts/HeadlineProvider";
import Recaptcha from "components/Recaptcha";
import { useRecaptcha } from "hooks/useRecaptcha";
import * as validationSchemas from "../validation/validationSchemas";
import { useFormik } from "formik";
import ActiveLightbox from "../components/ActiveLightbox";
import useLightbox from "../hooks/useLightbox";
import { useLoading } from "../contexts/LoadingProvider";
import PasswordHint from "../components/PasswordHint";
import "styles/negative-link.css";

const Consumer = () => {
  const [activeLightbox, openLightbox, closeAllLightboxes] = useLightbox();
  const locale = useParams().locale;
  const location = useLocation();
  const currentSearch = location.search;
  const { setHeadLine, setB2BsignUpTabVisible } = useContext(HeadlineContext);
  const [isPwShow, isConfirmPwShow, handlePwShow, handleConfirmPwShow] =
    usePWshow(false);
  const translations = useContext(TranslationContext);
  const { isLoading, setIsLoading } = useLoading();
  const [isChecked, setIsChecked] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [agreeToCreateAccount, setAgreeToCreateAccount] = useState("");
  const [emailBackendErrorMsg, setEmailBackendErrorMsg] = useState("");
  const [phoneBackendErrorMsg, setPhoneBackendErrorMsg] = useState("");
  const [countrySelectItems, setCountrySelectItems] = useState([]);
  const [languageSelectItems, setLanguageSelectItems] = useState([]);
  const [phonecodeSelectItems, setPhonecodeSelectItems] = useState([]);
  const [countrycontrolSelectItems, setCountrycontrolSelectItems] = useState([]);
  const [codeChallenge, setCodeChallenge] = useState("");
  const [state, setState] = useState("");
  const [selectedCountryCodeText, setSelectedCountryCodeText] = useState("");
  const recaptchaRef = React.useRef();
  const triggerButtonRef = useRef(null);
  const { resetRecaptcha } = useRecaptcha(recaptchaRef);

  useEffect(() => {
    setIsLoading(true);
    const fetchCountrySelectItems = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const state = utils.getQueryParamIgnoreCase(queryParams, "state") ?? "";
        const clientId = utils.getQueryParamIgnoreCase(queryParams, "clientid") ?? "";
        const redirectUrl = utils.getQueryParamIgnoreCase(queryParams, "redirecturl") ?
          utils.getQueryParamIgnoreCase(queryParams, "redirecturl") : (utils.getQueryParamIgnoreCase(queryParams, "redirect_uri") ?? "");
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/sign-up?clientId=${clientId}&RedirectUrl=${redirectUrl}&state=${state}`;
        const res = await utils.fetchApi(url);

        if (res.redirectUrl) {
          window.location.href = res.redirectUrl;
        }

        setCountrySelectItems(res.countrySelectItems);
        setLanguageSelectItems(res.languageSelectItems);
        setPhonecodeSelectItems(res.phonecodeSelectItems);
        setCountrycontrolSelectItems(res.countryControlSelectItems);
        setHeadLine(res.headline);
        setCodeChallenge(res.codeChallenge);
        setState(res.state);
        setB2BsignUpTabVisible(res.b2BsignUpTabVisible);
        setAgreeToCreateAccount(res.agreeToCreateAccount);

        formik.setFieldValue("SignupMobileDisplay", res.signupMobileDisplay);
        formik.setFieldValue("CountryCode", res.countryCode);
        formik.setFieldValue("LanguageCode", res.languageCode);
        formik.setFieldValue("ContactAgreement", res.signupOpinPretick);
        formik.setFieldValue("MobileCode", res.mobileCode);
        formik.setFieldValue("MobileColValidation", res.mobileColValidation);

        const country = res.countrySelectItems.find(item => item.value === res.countryCode);
        setSelectedCountryCodeText(country.text);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCountrySelectItems();
  }, []);

  const formik = useFormik({
    initialValues: {
      Email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      CountryCode: "",
      LanguageCode: "",
      ContactAgreement: false,
      SignupMobileDisplay: false,
      Recaptcha: "",
      MobileCode: "",
      MobileNum: "",
      MobileColValidation: ""
    },
    validationSchema: validationSchemas.signUpValidationSchema(translations),
    onSubmit: async (values, { setSubmitting }) => {
      var messagesCheck = [phoneBackendErrorMsg];
      if (messagesCheck.every(item => item !== '')) {
        return;
      }
      setErrorMsg("");
      setEmailBackendErrorMsg("");
      setIsLoading(true);
      try {
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/sign-up`;
        const res = await utils.fetchApi(url, "POST", values);

        if (res.success) {
          openLightbox(LightBoxEnum.EmailOtpCode);
        } else {
          setErrorMsg(res.errorMsg);
          res.errors.forEach(async (error) => {
            await formik.setFieldError(`${error.id}`, error.errorMsg);
            const errorInput = document.getElementsByName(`${error.id}`)[0];
            if (errorInput) {
              errorInput.focus();
            }
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
        setIsLoading(false);
        resetRecaptcha();
      }
    },
  });

  useEffect(() => {
    if (countrycontrolSelectItems !== undefined && formik.values.CountryCode !== "") {
      const selectedDisplay = countrycontrolSelectItems[formik.values.CountryCode].signupMobileDisplay;
      formik.setFieldValue("SignupMobileDisplay", selectedDisplay);
      if (!selectedDisplay) { formik.setFieldValue("MobileNum", ""); }
    }
  }, [formik.values.CountryCode]);

  const handleEmailBlur = async (e) => {
    setEmailBackendErrorMsg("");

    const res = await validateHelper.checkEmailExists(
      e.target.value,
      locale,
      translations
    );
    if (!res.success) {
      await formik.setTouched({ ...formik.touched, Email: true });

      if (formik.values.Email.trim() === "") {
        setEmailBackendErrorMsg(translations.EmptyErrorMsg);
      } else {
        setEmailBackendErrorMsg(res.error);
      }
    }
  };

  const handlePasswordBlur = async (e) => {
    const passwordValue = e.target.value;
    if (passwordValue.match(/^\S+$/) && !passwordValue.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@@#$%^&*()_+]{9,}$/) && passwordValue.length > 7) {
      await formik.setTouched({ ...formik.touched, password: true });
      await formik.setFieldError("password", translations.PasswordHint);
    }
  };

  const handlePolicyChange = async (e) => {
    setIsChecked(!isChecked);
  };

  const handleCountryChange = async (e) => {

    formik.handleChange(e);
    const selectedIndex = e.target.selectedIndex;
    const seletedCountryText = e.target.options[selectedIndex].text;
    setSelectedCountryCodeText(seletedCountryText);
    phonecodeSelectItems.forEach(element => {
      if (element.value.includes(seletedCountryText)) {
        formik.setFieldValue("MobileCode", element.value);
      }
    });
  };

  const handlePhoneBlur = async (e) => {
    const phoneVal = e.target.value;
    await formik.setTouched({ ...formik.touched, MobileNum: true });
    if (phoneVal.length === 0) {
      setPhoneBackendErrorMsg(translations.EmptyErrorMsg);
    }
    else if (phoneVal.length > 0 && formik.values.MobileColValidation !== "") {
      setPhoneBackendErrorMsg("");
      switch (selectedCountryCodeText) {
        case "India":
          if (phoneVal.length < 10 || phoneVal.length > 10) {
            setPhoneBackendErrorMsg(utils.replaceStrToNum(translations.TheFieldIsLimited, 10));
          }
          else if (!phoneVal.match(formik.values.MobileColValidation)) {
            setPhoneBackendErrorMsg(translations.FormatError);
          }
          break;
      }

    }
  };
  return (
    <Fragment>
      <div className="agw-tab-pane">
        <div className="agw-mb-300 agw-col-md-5 agw-mx-auto">
          <p className="agw-fs-body-l agw-mb-150">
            {translations.CreateAcerId}&nbsp;
            <Link
              className="agw-fw-semibold agw-link-primary agw-text-decoration-underline"
              style={{ cursor: "pointer" }}
              target="_blank"
              to={`/${locale}/whats-acer-id`}
            >
              {translations.LearnMoreAboutAcerId}
            </Link>
          </p>
          <p className="agw-fs-body-l agw-mb-0">
            {translations.alreadyAMember}&nbsp;
            <Link
              className="agw-fw-semibold agw-link-acer agw-text-decoration-underline"
              style={{ cursor: "pointer" }}
              to={`/${locale}/account/sign-in${currentSearch}`}
            >
              {translations.SignIn}
            </Link>
          </p>
        </div>

        <form
          className="agw-col-md-5 agw-mx-auto"
          onSubmit={formik.handleSubmit}
        >
          <div className="agw-row agw-gy-150">
            <div className="agw-col-12">
              <label htmlFor="Email" className="agw-form-label agw-fw-semibold">
                {translations.emailAddressRegister}
                <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                  *
                </span>
              </label>
              <input
                type="Email"
                className={`agw-form-control ${formik.touched.Email &&
                  (formik.errors.Email || emailBackendErrorMsg)
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="Email"
                name="Email"
                onChange={formik.handleChange}
                onBlur={handleEmailBlur}
                value={formik.values.Email}
                aria-invalid={formik.touched.Email && formik.errors.Email ? true : false}
                aria-required="true"
                aria-describedby="emailError"
              />
              {formik.touched.Email &&
                (formik.errors.Email || emailBackendErrorMsg) ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: formik.errors.Email ?? emailBackendErrorMsg,
                  }}
                  className="agw-d-block agw-mt-050 agw-text-negative"
                  id="emailError"
                  aria-live="assertive" aria-atomic="true"
                ></span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="password"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.passwordRegister}
                <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                  *
                </span>
              </label>
              <div className="agw-po-relative">
                <input
                  type={isPwShow ? "text" : "password"}
                  className={`agw-form-control ${formik.touched.password && formik.errors.password && (formik.errors.password !== translations.PasswordHint) ? "agw-border-negative" : ""}`}
                  id="password"
                  name="password"
                  onChange={(e) => {
                    formik.setTouched({ ...formik.touched, password: true });
                    formik.handleChange(e);
                    handlePasswordBlur(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  aria-invalid={formik.touched.password && formik.errors.password ? true : false}
                  aria-required="true"
                  aria-describedby="passwordError"
                />
                <button
                  className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pe-100"
                  style={{ transform: "translate(-100%,-50%)" }}
                  onClick={(e) => { handlePwShow(e); }}
                  aria-label={`${isPwShow ? translations.showPassword : translations.hidePassword}`}
                  type="button"
                >
                  <span className={`agw-icon-visibility-off agw-fs-title-m agw-pe-none ${isPwShow ? "agw-d-none" : ""}`}></span>
                  <span className={`agw-icon-visibility agw-fs-title-m agw-pe-none ${isPwShow ? "" : "agw-d-none"}`}></span>
                </button>
              </div>
              <PasswordHint
                error={formik.errors.password}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <span
                  className={`agw-d-block agw-mt-050 ${formik.errors.password === translations.PasswordHint
                    ? "agw-text-gray-900"
                    : "agw-text-negative"
                    }`}
                  id="passwordError"
                  aria-live="assertive" aria-atomic="true"
                >
                  {formik.errors.password}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="confirmPassword"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.ConfirmPassword}
                <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                  *
                </span>
              </label>
              <div className="agw-po-relative">
                <input
                  type={isConfirmPwShow ? "text" : "password"}
                  className={`agw-form-control ${formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                    ? "agw-border-negative"
                    : ""
                    }`}
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  onPaste={(e) => e.preventDefault()}
                  aria-required="true"
                  aria-invalid={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
                  aria-describedby="confirmPasswordError"
                />
                <button
                  className="agw-btn agw-po-absolute agw-top-50 agw-left-100 agw-pe-100"
                  style={{ transform: "translate(-100%,-50%)" }}
                  onClick={(e) => { handleConfirmPwShow(e); }}
                  aria-label={`${isConfirmPwShow ? translations.showPassword : translations.hidePassword}`}
                  type="button"
                >
                  <span className={`agw-icon-visibility-off agw-fs-title-m agw-pe-none ${isConfirmPwShow ? "agw-d-none" : ""}`}></span>
                  <span className={`agw-icon-visibility agw-fs-title-m agw-pe-none ${isConfirmPwShow ? "" : "agw-d-none"}`}></span>
                </button>
              </div>
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                <span className="agw-d-block agw-mt-050 agw-text-negative"
                  id="confirmPasswordError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.confirmPassword}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="firstName"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.firstNameRegister}
                <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                  *
                </span>
              </label>
              <input
                type="text"
                className={`agw-form-control ${formik.touched.firstName && formik.errors.firstName
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="firstName"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                aria-required="true"
                aria-invalid={formik.touched.firstName && formik.errors.firstName ? true : false}
                aria-describedby="firstNameError"
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <span className="agw-d-block agw-mt-050 agw-text-negative"
                  id="firstNameError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.firstName}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label
                htmlFor="lastName"
                className="agw-form-label agw-fw-semibold"
              >
                {translations.lastNameRegister}
                <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                  *
                </span>
              </label>
              <input
                type="text"
                className={`agw-form-control ${formik.touched.lastName && formik.errors.lastName
                  ? "agw-border-negative"
                  : ""
                  }`}
                id="lastName"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                aria-required="true"
                aria-invalid={formik.touched.lastName && formik.errors.lastName ? true : false}
                aria-describedby="lastNameError"
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <span className="agw-d-block agw-mt-050 agw-text-negative"
                  id="lastNameError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.lastName}
                </span>
              ) : null}
            </div>
            <div className={`agw-col-12 ${formik.values.SignupMobileDisplay ? "" : "agw-d-none"}`}>
              <div className="agw-d-flex agw-align-items-end">
                <div className="agw-col-6">
                  <label htmlFor="MobileCode" className="agw-form-label">
                    {translations.countryCodeMyProfile}
                    <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                      *
                    </span>
                  </label>
                  <select
                    id="MobileCode"
                    className={`agw-form-select ${formik.touched.MobileCode && formik.errors.MobileCode
                      ? "agw-border-negative"
                      : ""
                      }`}
                    name="MobileCode"
                    onChange={formik.handleChange}
                    value={formik.values.MobileCode}
                    disabled="disabled"
                  >
                    {phonecodeSelectItems.map((item) => (
                      <option key={item.value} value={item.value} disabled={item.disabled}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                  {formik.touched.MobileCode && formik.errors.MobileCode ? (
                    <span className="agw-d-block agw-mt-050 agw-text-negative">
                      {formik.errors.MobileCode}
                    </span>
                  ) : null}
                </div>
                <div className="agw-col-6">
                  <label htmlFor="MobileNum" className="agw-form-label">
                    {translations.phoneSignIn}
                    <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className={`agw-form-control ${formik.touched.MobileNum && (formik.errors.MobileNum || phoneBackendErrorMsg)
                      ? "agw-border-negative"
                      : ""
                      }`}
                    id="MobileNum"
                    name="MobileNum"
                    onChange={formik.handleChange}
                    onBlur={handlePhoneBlur}
                    value={formik.values.MobileNum}
                    aria-required="true"
                    aria-invalid={formik.touched.MobileNum && formik.errors.MobileNum ? true : false}
                    aria-describedby="MobileNumError"
                  />

                </div>
              </div>
              {formik.touched.MobileNum && (formik.errors.MobileNum || phoneBackendErrorMsg) ? (
                <span className="agw-d-block agw-mt-050 agw-text-negative"
                  id="MobileNumError"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.MobileNum ?? phoneBackendErrorMsg}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label htmlFor="CountryCode" className="agw-form-label">
                {translations.Region}
                <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                  *
                </span>
              </label>
              <select
                id="CountryCode"
                className={`agw-form-select ${formik.touched.CountryCode && formik.errors.CountryCode
                  ? "agw-border-negative"
                  : ""
                  }`}
                name="CountryCode"
                value={formik.values.CountryCode}
                onChange={handleCountryChange}
                aria-required="true"
              >
                {countrySelectItems.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
              {formik.touched.CountryCode && formik.errors.CountryCode ? (
                <span className="agw-d-block agw-mt-050 agw-text-negative"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.CountryCode}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12">
              <label htmlFor="LanguageCode" className="agw-form-label">
                {translations.languageMyProfile}
                <span className="agw-fw-semibold agw-text-negative agw-ms-050">
                  *
                </span>
              </label>
              <select
                id="LanguageCode"
                className={`agw-form-select ${formik.touched.LanguageCode && formik.errors.LanguageCode
                  ? "agw-border-negative"
                  : ""
                  }`}
                name="LanguageCode"
                value={formik.values.LanguageCode}
                onChange={formik.handleChange}
                type="select"
                aria-required="true"
              >
                {languageSelectItems.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
              {formik.touched.LanguageCode && formik.errors.LanguageCode ? (
                <span className="agw-d-block agw-mt-050 agw-text-negative"
                  aria-live="assertive" aria-atomic="true">
                  {formik.errors.LanguageCode}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12 agw-gy-300">
              <Recaptcha
                recaptchaRef={recaptchaRef}
                formik={formik}
              />
              {formik.touched.Recaptcha && formik.errors.Recaptcha ? (
                <span className="agw-d-block agw-mt-050 agw-text-center agw-text-negative">
                  {formik.errors.Recaptcha}
                </span>
              ) : null}
            </div>
            <div className="agw-col-12 agw-gy-300">
              <div className="agw-form-check">
                <input
                  type="checkbox"
                  className="agw-form-check-input"
                  id="ConsumerPolicyCheckbox"
                  checked={isChecked}
                  onChange={handlePolicyChange}
                />
                <label
                  className="agw-form-check-label"
                  htmlFor="ConsumerPolicyCheckbox"
                  dangerouslySetInnerHTML={{
                    __html: utils.replaceStrToNum(agreeToCreateAccount, locale),
                  }}
                ></label>
              </div>
            </div>
            <div className="agw-col-12">
              <div className="agw-form-check">
                <input
                  type="checkbox"
                  className="agw-form-check-input"
                  id="ConsumerAgreeCheckbox"
                  name="ContactAgreement"
                  checked={formik.values.ContactAgreement}
                  onChange={formik.handleChange}
                />
                <label
                  className="agw-form-check-label"
                  htmlFor="ConsumerAgreeCheckbox"
                >
                  {translations.agreeToSubscribe}
                </label>
              </div>
            </div>
            <div className="agw-col-12 agw-gy-300">
              {errorMsg ? (
                <p className="agw-text-negative agw-text-center">{errorMsg}</p>
              ) : null}
              <button
                type="submit"
                className="agw-btn agw-btn-block agw-btn-primary"
                disabled={!formik.isSubmitting && !isChecked}
                ref={triggerButtonRef}
              >
                {translations.SignUp}
              </button>
            </div>
          </div>
        </form>

        <div className="agw-d-flex agw-justify-content-center agw-align-items-center agw-po-relative">
          <hr className="agw-po-absolute agw-w-100" style={{ zIndex: -1 }} />
          <span className="agw-my-300 agw-px-150 agw-bg-white agw-text-gray-600 agw-fs-body-l">
            {translations.orSignIn}
          </span>
        </div>

        <MediaBtn state={state} codeChallenge={codeChallenge} />
      </div>
      <ActiveLightbox
        activeLightbox={activeLightbox}
        email={formik.values.Email}
        onClose={closeAllLightboxes}
        onOpenLightbox={openLightbox}
        triggerButtonRef={triggerButtonRef}
      />
    </Fragment>
  );
};

export default Consumer;
