import { useState, useEffect } from 'react';

const useLightbox = () => {
  const [activeLightbox, setActiveLightbox] = useState(null);

  const openLightbox = (lightboxId) => {
    setActiveLightbox(lightboxId);
  };

  const closeAllLightboxes = () => {
    setActiveLightbox(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeAllLightboxes();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return [activeLightbox, openLightbox, closeAllLightboxes];
};

export default useLightbox;
