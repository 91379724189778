import { React, useState, useEffect} from "react";
import { useParams, useLocation } from "react-router-dom";
import * as utils from "../helpers/utilityHelpers";

function Header() {
  const locale = useParams().locale;
  const location = useLocation();
  const [body, setBody] = useState("");

  useEffect(() => {

    const fetchHeader = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/${locale}/api/account/getlayout?type=header`, {
          method: 'GET'
        });
        if (response.ok) {
          const headerHtml = await response.text();
          const parser = new DOMParser();
          const doc = parser.parseFromString(headerHtml, 'text/html');
          // console.log(doc.body.innerHTML);
          const headElements = doc.head.querySelectorAll('link, script');
          const bodyElements = doc.body.querySelectorAll('script[src]'); 
          const insertedElements = [];
          const target = ["main.css","acer-icons.css"];
          // console.log(doc.head.querySelectorAll('link'));
          headElements.forEach(element => {
            // console.log(element.href);
            const href = element.getAttribute('href') || '';
            if(target.some((path) => href.includes(path)))
            {
              // console.log(element.href);
              element.remove();
            }
            else
            {
              const clonedElement = element.cloneNode(true);
              document.head.appendChild(clonedElement);
              insertedElements.push(clonedElement);
            }
            //   const clonedElement = element.cloneNode(true);
            //   document.head.appendChild(clonedElement);
            //   insertedElements.push(clonedElement);
            // }else
            // {
            //   element.remove();
            // }
          });
          //  const target = ['main.bundle.js', 'utility.js'];
           bodyElements.forEach(element => {
            const script = document.createElement('script');
            script.src = element.src;
            script.async = true;
            // if(target.some((path) => location.pathname.includes(path)))
            // {
              
              document.body.appendChild(script); 
            // }
            // else
            // {
            //   document.body.appendChild(script);
            // }
            element.parentNode.removeChild(element); 
          });
          ;
          const inlineScripts = doc.body.querySelectorAll('script:not([src])');
          inlineScripts.forEach(inlineScript => {
            if(!inlineScript.textContent.includes("$("))
            {
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.textContent = inlineScript.textContent; // 將內嵌的 JS 代碼複製到新的 script 中
              document.body.appendChild(script);
            }
              
              inlineScript.parentNode.removeChild(inlineScript); // 移除原有的 <script> 標籤
          });
          
          // doc.body.querySelectorAll('noscript').forEach(element => {
          //   element.parentNode.removeChild(element); 
          // });
          


          const token = utils.getCookie("agw-uuid");

          const loginElement = doc.body.querySelectorAll(".menu--account-login"); 
          // const notLoginElement = doc.body.querySelectorAll(".menu--account-not-login");
          const mobileLoginElements = doc.body.querySelectorAll(".menu--account-mobile-login");
          // const mobileNotLoginElements = doc.body.querySelectorAll(".menu--account-mobile-not-login");
          if (token) {
            loginElement[0]?.classList.remove("agw-d-none");
            // notLoginElement[0]?.classList.add("agw-d-none");
            mobileLoginElements.forEach((item) => item.classList.remove("agw-d-none"));
            // mobileNotLoginElements.forEach((item) => item.classList.add("agw-d-none"));
          }

          setBody(doc.body.innerHTML);
          return () => {
            insertedElements.forEach(el => document.head.removeChild(el));
          };
            
        }
        
      } catch (error) {
        console.error(error);
      }
    };

    fetchHeader();
  }, [locale]);

  return (
     <div dangerouslySetInnerHTML={{ __html: body  }} />
  );
}

export default Header;