import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useLoading } from "../contexts/LoadingProvider";
import { usePrevLink } from "../contexts/PrevLinkProvider";
import { TranslationContext } from "contexts/TranslationProvider";
import * as utils from "../helpers/utilityHelpers";

function Footer() {
  const locale = useParams().locale;
  const translations = useContext(TranslationContext);
  const location = useLocation();
  const currentUrl = window.location.origin + location.pathname;
  const currentYear = new Date().getFullYear();
  const { isLoading, setIsLoading } = useLoading();
  const { prevLink, setPrevLink } = usePrevLink();
  const [languageSelectorData, setLanguageSelectorData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchLanguageSelector = async () => {
      try {
        const url = `${process.env.REACT_APP_URL}/${locale}/api/account/getwebsite`;
        const response = await utils.fetchApi(url);

        setLanguageSelectorData(response);
      } catch (error) {
        console.error(error);
        console.log(
          `${process.env.REACT_APP_URL}/${locale}/api/account/getwebsite`
        );
      }
    };

    fetchLanguageSelector();
    setIsLoading(false);
  }, [locale]);

  const handleButtonClick = () => {
    const langLocale = window.location.pathname.split("account/")[1];
    console.log("Previous Page Path:", langLocale);

    if (langLocale !== "languages") {
      setPrevLink(langLocale);
    }
  };

  return (
    <footer className="agw-bg-gray-200 agw-pt-150 agw-pb-300">
      <div className="agw-d-flex agw-flex-column agw-align-items-center">
        <div className="footer-links agw-mb-150">
          <div className="footer-links_item">
            <Link
              className="agw-fs-body-s animation_hover agw-text-decoration-none"
              to={`/${locale}/privacy`}
              target="_blank"
            >
              {translations.Privacy}
            </Link>
          </div>
          <div className="footer-links_item">
            <Link
              className="agw-fs-body-s animation_hover agw-text-decoration-none"
              to={`/${locale}/legal`}
              target="_blank"
            >
              {translations.Legal}
            </Link>
          </div>
        </div>
        {!currentUrl.includes("business-sign-up") ? (
          <Link
            className="agw-d-inline-flex agw-align-items-center agw-justify-content-center agw-mb-3 agw-fs-body-s agw-fw-semibold agw-text-decoration-none agw-link-primary"
            to={`/${locale}/account/languages`}
            onClick={handleButtonClick}
          >
            <span className="agw-icon-globe agw-fs-title-s agw-me-050"></span>
            {languageSelectorData
              .filter(
                (values) =>
                  values.countryCode.includes(locale.split("-")[0]) &&
                  values.languageCode.includes(locale.split("-")[1])
              )
              .map((item) => (
                <span key={item.countryCode}>
                  {item.countryName} - {item.languageName}
                </span>
              ))}
          </Link>
        ) : null}
        <div>
          <span className="agw-fs-body-s">&copy; {currentYear} Acer Inc.</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
