import { useState, useEffect, useRef } from "react";
import * as utils from "../helpers/utilityHelpers";

const useOTPCountdown = (initialSeconds, initialSecondsResend) => {
  const [seconds, setCountdown] = useState(initialSeconds);
  const [resendSeconds, setResendCountdown] = useState(initialSecondsResend);
  const clearMainCountdownRef = useRef(null);
  const clearResendCountdownRef = useRef(null);
  const startCountdowns = () => {
    // 清理計時器
    if (clearMainCountdownRef.current) clearMainCountdownRef.current();
    if (clearResendCountdownRef.current) clearResendCountdownRef.current();
    

    // 啟動主要倒數計時
    clearMainCountdownRef.current = utils.startOTPCountdown(
      initialSeconds,
      setCountdown,
      null
    );

    // 啟動重發倒數計時
    clearResendCountdownRef.current = utils.startOTPCountdown(
      initialSecondsResend,
      setResendCountdown,
      null 
    );
  };

  const resetCountdowns = () => {
    // // 清理計時器
    // if (clearMainCountdownRef.current) clearMainCountdownRef.current();
    // if (clearResendCountdownRef.current) clearResendCountdownRef.current();

    // 重置狀態
    setCountdown(initialSeconds);
    setResendCountdown(initialSecondsResend);

    // 重新啟動倒數
    startCountdowns();
  };

  useEffect(() => {
    startCountdowns();

    return () => {
      if (clearMainCountdownRef.current) clearMainCountdownRef.current();
      if (clearResendCountdownRef.current) clearResendCountdownRef.current();
    };
  }, []);

  return {
    seconds,
    resendSeconds,
    resetCountdowns,
  };
};

export default useOTPCountdown;
