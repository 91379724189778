import React, { Fragment, useState, useContext, useEffect, createRef, useRef } from "react";
import { TranslationContext } from "../contexts/TranslationProvider";
import { useParams, useLocation } from "react-router-dom";
import { useLoading } from "../contexts/LoadingProvider";
import * as utils from "../helpers/utilityHelpers";
import "styles/lightbox.css";
import useFocusTrap from '../hooks/useFocusTrap';
import useFocusManager from '../hooks/useFocusManager';
import useOTPCountdown from "../hooks/useOTPCountdown ";

// Otp驗證
function  VerifyOtpEC () {
  const initialSeconds = 100;
  const initialSecondsResend = 60;
  const location = useLocation();
  const translations = useContext(TranslationContext);
  const { locale } = useParams();
  const { isLoading, setIsLoading } = useLoading();
  const [code, setCode] = useState(Array(6).fill(""));
  const codeRefs = Array(6)
    .fill()
    .map(() => createRef());
  const [errorMsg, setErrorMsg] = useState("");
  const lightboxRef = useRef(null);
  const [key, setKey] = useState("");
  const { seconds, resendSeconds, resetCountdowns } = useOTPCountdown(
    initialSeconds,
    initialSecondsResend
  );
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramkey = utils.getQueryParamIgnoreCase(queryParams, "key") ?? "";
    if(paramkey){ setKey(paramkey) };

    if (codeRefs[0].current) {
      codeRefs[0].current.focus();
    }
  }, [locale]);

  useEffect(() => {
    if (seconds <= 0) {
      setErrorMsg( translations.codeExpired + "&nbsp;" + translations.PleaseClickResendCode);
    }
  }, [seconds]);

  const handleResetTimer = () => {
    resetCountdowns();
    setErrorMsg("");
  };

  const handleKeyDown = (index, e) => {
    const value = e.key;
    if (value === "Backspace") {
      const newCode = [...code];
      if (!newCode[index] && index !== 0) {
        var goToIndex = index;

        if (newCode[index].length > 0) {
          newCode[index] = "";
        } else if (newCode[index] === "") {
          goToIndex -= 1;
          newCode[goToIndex] = "";
        }

        setCode(newCode);
        codeRefs[goToIndex].current.focus();
      }
      return;
    }
  };

  const handleChange = (index, e) => {
    let value = e.target.value;
    if (!utils.isNumber(value)) {
      return;
    }

    if (value.length > 1) {
      value = value.slice(1, 2);
    }
    const newCode = [...code];
    newCode[index] = value;

    setCode(newCode);

    if (value && index < codeRefs.length - 1) {
      codeRefs[index + 1].current.focus();
    }
  };

  const handleOnclick = (index, e) => {
    codeRefs[index].current.setSelectionRange(1, 1);
  };

  const handlePaste = (e) => {
    let pasteData = e.clipboardData.getData("text").trim().split("");
    if (pasteData.length === 6) {
      setCode(pasteData);
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_URL}/${locale}/api/account/email-verify?veridationCode=${code.join("")}`;
    try {
      const data = { code : code.join(""), key  };
      const res = await utils.fetchApi(url, "POST", data);
      if (res.success) {
        window.location.href = res.redirectUrl;
      } else {
        setCode(Array(6).fill(""));
        setErrorMsg(res.errorMsg);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async (e) => {
    setIsLoading(true);
     const url = `${process.env.REACT_APP_URL}/${locale}/api/account/resendFromEC?key=${key}`;
    try {
      const res = await utils.fetchApi(url);
      if (res.success) {
        window.location.href = location.search;
        handleResetTimer();
      } else {
        setErrorMsg(res.errorMsg);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  useFocusTrap(lightboxRef);
  useFocusManager(true, true);



  return (
    <Fragment>
      <div className={`agw-lightbox ${locale ? "agw-d-block" : ""}`}
        role="dialog"
        ref={lightboxRef}
        aria-labelledby="verifyEmail"
        aria-describedby="verifyEmailDescription"
      >
        <div className="agw-lightbox-box agw-d-flex agw-justify-content-center agw-align-items-center agw-h-100 agw-col-md-6">
          <div className="agw-bg-white agw-w-100">
            <div className="agw-container agw-py-6">
              <div className="agw-row agw-gy-3">
                <div className="agw-col-12">
                    <img className="header-logo agw-mx-auto agw-d-block" src="https://images.acer.com/is/content/acer/acer-4" alt="acer logo" />
                </div>
                <div className="agw-col-12">
                  <h5
                    className="agw-text-center agw-fs-title-l agw-mb-0"
                    id="verifyEmail"
                    dangerouslySetInnerHTML={{
                      __html: translations.verifyEmail
                    }}
                  ></h5>
                </div>
                <div className="agw-col-12" id="verifyEmailDescription">
                  <p className="agw-text-center agw-fs-body-l agw-mb-0">
                  &nbsp;
                  </p>
                  <p className="agw-text-center agw-fs-body-l agw-mb-0">
                    {translations.enterCode}
                  </p>
                </div>
                <div className="validemail-lb">
                  <div className="lb-verification-area">
                    {codeRefs.map((ref, index) => (
                      <div className="verification-code" key={index}>
                        <input
                          type="text"
                          ref={ref}
                          value={code[index]}
                          className={`verification-input`}
                          onPaste={handlePaste}
                          onChange={(e) => handleChange(index, e)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          onClick={(e) => handleOnclick(index, e)}
                        />
                      </div>
                    ))}
                  </div>
                  <span
                    className="agw-d-block agw-mt-1 agw-text-negative agw-text-center"
                    dangerouslySetInnerHTML={{
                      __html: errorMsg,
                    }}
                  ></span>
                </div>
                
                {/* <div className="agw-col-12">
                  {seconds > 0 ? (
                    <p className="agw-text-center agw-fs-body-l agw-mb-0">
                      {translations.codeExpires} &nbsp;
                      {utils.formatOTPTime(seconds)}
                    </p>
                  ) : ""}
                </div> */}

                <div className="agw-col-12 agw-text-center">
                  <button
                    type="submit"
                    className="agw-btn agw-btn-primary"
                    onClick={handleSubmit}
                    disabled={seconds <= 0}
                  >
                    {translations.Submit}
                  </button>
                </div>
                <div className="agw-col-12">
                  <div className="agw-d-flex agw-flex-wrap agw-align-items-center agw-justify-content-center agw-fs-body-l agw-mb-0">
                    <span >{translations.didntGetCode}</span>
                    {resendSeconds > 0 ? (
                      <button
                        type="button"
                        className="agw-btn agw-btn-tertiary-black"
                        onClick={handleResend}
                        disabled
                      >
                        {translations.resendCode}&nbsp;({utils.formatOTPTime(resendSeconds)})
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="agw-btn agw-btn-tertiary-black agw-text-green-700"
                        onClick={handleResend}
                      >
                        {translations.resendCode}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VerifyOtpEC;
