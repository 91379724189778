import * as Yup from "yup";
import * as utils from "../helpers/utilityHelpers";
import { noHtmlTagRegex } from "../helpers/validationHelpers";

export const signInValidationSchema = (translations) => {
  return Yup.object().shape({
    Account: Yup.string()
      .email(translations.EmailError)
      .required(translations.EmptyErrorMsg),
    Password: Yup.string().required(translations.EmptyErrorMsg),
  });
};

export const enterNewPwdValidationSchema = (translations) => {
  return Yup.object().shape({
    NewPassword: Yup.string().min(8, translations.LengthError)
      .matches(/^\S*$/, translations.FormatError)
      .required(translations.EmptyErrorMsg),
    ConfirmNewPassword: Yup.string()
      .oneOf([Yup.ref("NewPassword"), null], translations.InconsistentPasswords)
      .required(translations.EmptyErrorMsg),
  });
};

export const resetPasswordValidationSchema = (translations) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(translations.EmailError)
      .required(translations.EmptyErrorMsg),
    Recaptcha: Yup.string().required(translations.PleaseValidCaptcha),
  });
};

export const updatePasswordValidationSchema = (translations) => {
  return Yup.object().shape({
    NewPassword: Yup.string()
      .min(8, translations.LengthError)
      .matches(/^\S*$/, translations.FormatError)
      .required(translations.EmptyErrorMsg),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref("NewPassword"), null], translations.InconsistentPasswords)
      .required(translations.EmptyErrorMsg),
  });
};

export const signUpValidationSchema = (translations) => {
  return Yup.object().shape({
    Email: Yup.string()
      .required(translations.EmptyErrorMsg)
      .email(translations.EmailError)
      .matches(noHtmlTagRegex, translations.FormatError),
    password: Yup.string()
      .matches(/^\S*$/, translations.FormatError)     
      .matches(noHtmlTagRegex, translations.FormatError)
      .required(translations.EmptyErrorMsg)
      .min(8, translations.LengthError),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], translations.InconsistentPasswords)
      .matches(noHtmlTagRegex, translations.FormatError)
      .required(translations.EmptyErrorMsg),
    firstName: Yup.string()
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError)
      .max(50, utils.replaceStrToNum(translations.TheFieldIsLimited, 50)),
    lastName: Yup.string()
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError)
      .max(50, utils.replaceStrToNum(translations.TheFieldIsLimited, 50)),
    CountryCode: Yup.string().required(translations.EmptyErrorMsg),
    LanguageCode: Yup.string().required(translations.EmptyErrorMsg),
    Recaptcha: Yup.string().required(translations.PleaseValidCaptcha),
  });
};

export const businessSignUpValidationSchema = (translations) => {
  return Yup.object().shape({
    ...signUpValidationSchema(translations).fields,
    BusinessId: Yup.string()
      .max(20, utils.replaceStrToNum(translations.TheFieldIsLimited, 20))
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError),
    businessName: Yup.string()
      .max(60, utils.replaceStrToNum(translations.TheFieldIsLimited, 60))
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError),
    Address: Yup.string()
      .min(2, utils.replaceStrToNum(translations.MoreThanErrorMsg, 2))
      .max(80, utils.replaceStrToNum(translations.LessThenErrorMsg, 80))
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError),
    City: Yup.string()
      .min(2, utils.replaceStrToNum(translations.MoreThanErrorMsg, 2))
      .max(40, utils.replaceStrToNum(translations.LessThenErrorMsg, 40))
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError),
    ZipCode: Yup.string()
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError),
    Phone: Yup.string()
    .required(translations.EmptyErrorMsg)
    .matches(noHtmlTagRegex, translations.FormatError),
    
  });
};

export const federatedSignUpValidationSchema = (translations) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(translations.EmailError)
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError),
    firstName: Yup.string()
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError),
    lastName: Yup.string()
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError),
    CountryCode: Yup.string().required(translations.EmptyErrorMsg),
    LanguageCode: Yup.string().required(translations.EmptyErrorMsg),
    Recaptcha: Yup.string().required(translations.PleaseValidCaptcha),
  });
};

export const myProfileValidationSchema = (translations) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError)
      .max(50, utils.replaceStrToNum(translations.TheFieldIsLimited, 50))
      .test('no-semicolon', translations.FormatError, value => !/;/.test(value)),
    lastName: Yup.string()
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError)
      .max(50, utils.replaceStrToNum(translations.TheFieldIsLimited, 50))
      .test('no-semicolon', translations.FormatError, value => !/;/.test(value)),
    countryCode: Yup.string().required(translations.EmptyErrorMsg),
    oldPassword: Yup.string()
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError),
    newPassword: Yup.string()
    .matches(noHtmlTagRegex, translations.FormatError)
    .when('oldPassword', (oldPassword, schema) => {
      oldPassword = Array.isArray(oldPassword) ? oldPassword[0] : oldPassword;
      if (oldPassword && oldPassword !== "password") {
        return schema
          .required(translations.EmptyErrorMsg)
          .min(8, translations.LengthError)
          .matches(/^\S*$/, translations.FormatError);
      }
      return schema.notRequired();
    }), 
    confirmPassword: Yup.string()
      .matches(noHtmlTagRegex, translations.FormatError)
      .when('oldPassword', (oldPassword, schema) => {
        oldPassword = Array.isArray(oldPassword) ? oldPassword[0] : oldPassword;
        if (oldPassword && oldPassword !== "password") {
          return schema
            .required(translations.EmptyErrorMsg)
            .oneOf([Yup.ref("newPassword"), null], translations.InconsistentPasswords);
        }
        return schema.notRequired();
      }),
    email: Yup.string()
      .required(translations.EmptyErrorMsg)
      .matches(noHtmlTagRegex, translations.FormatError)
      .email(translations.EmailError),
    phonePrefixCode: Yup.string()
      .matches(noHtmlTagRegex, translations.FormatError)
      .test('phone-prefix-code-test', translations.PleaseChoose, function (value) {
        const { phoneNumber } = this.parent;
        return !(phoneNumber && !value);
      }),
    phoneNumber: Yup.string()
      .test('phone-number-test', translations.PleaseInputANumber, function (value) {
        const { phonePrefixCode } = this.parent;
        return !(phonePrefixCode && !value);
      }),
    mobilePhonePrefixCode: Yup.string()
      .test('mobile-phone-prefix-code-test', translations.PleaseChoose, function (value) {
        const { mobilePhoneNumber } = this.parent;
        return !(mobilePhoneNumber && !value);
      }),
    mobilePhoneNumber: Yup.string()
      .test('mobile-phone-number-test', translations.PleaseInputANumber, function (value) {
        const { mobilePhonePrefixCode } = this.parent;
        return !(mobilePhonePrefixCode && !value);
      })
      .test('mobile-phone-length-test', utils.replaceStrToNum(translations.TheFieldIsLimited, 10), function (value) {
        const { mobilePhonePrefixCode } = this.parent;
        return mobilePhonePrefixCode === 'India +91' ? value.length === 10 : true;
      }),
    shippingAddr: Yup.object().shape({
      address: Yup.string()
        .matches(noHtmlTagRegex, translations.FormatError)
        .test('address-test', translations.EmptyErrorMsg, function (value) {
          const { countryCode, state, postcode, city } = this.parent;
          if ((countryCode || state || postcode || city) && !value) {
            return false;
          }
          return true;
        }),
      address2: Yup.string()
        .matches(noHtmlTagRegex, translations.FormatError),
      city: Yup.string()
        .matches(noHtmlTagRegex, translations.FormatError)
        .test('shippingState-test', translations.EmptyErrorMsg, function (value) {
          const { countryCode, address, address2, postcode, state } = this.parent;
          if ((countryCode || address || address2 || postcode || state) && !value) {
            return false;
          }
          return true;
        }),
      state: Yup.string()
        .matches(noHtmlTagRegex, translations.FormatError)
        .test('city-test', translations.EmptyErrorMsg, function (value) {
          const { countryCode, address, address2, postcode, city } = this.parent;
          if ((countryCode || address || address2 || postcode || city) && !value) {
            return false;
          }
          return true;
        }),
      postcode: Yup.string()
        .matches(noHtmlTagRegex, translations.FormatError)
        .test('postcode-test', translations.EmptyErrorMsg, function (value) {
          const { countryCode, address, address2, city, state } = this.parent;
          if ((countryCode || address || address2 || city || state) && !value) {
            return false;
          }
          return true;
        }),
      countryCode: Yup.string()
        .test('countryCode-test', translations.EmptyErrorMsg, function (value) {
          const { address, address2, postcode, city, state } = this.parent;
          if ((address || address2 || postcode || city || state) && !value) {
            return false;
          }
          return true;
        }),
    }),
    billingAddr: Yup.object().shape({
      address: Yup.string()
        .matches(noHtmlTagRegex, translations.FormatError)
        .test('address-test', translations.EmptyErrorMsg, function (value) {
          const { countryCode, state, postcode, city } = this.parent;
          if ((countryCode || state || postcode || city) && !value) {
            return false;
          }
          return true;
        }),
      address2: Yup.string()
        .matches(noHtmlTagRegex, translations.FormatError),
      city: Yup.string()
        .matches(noHtmlTagRegex, translations.FormatError)
        .test('Billing-State-test', translations.EmptyErrorMsg, function (value) {
          const { countryCode, address, address2, postcode, state } = this.parent;
          if ((countryCode || address || address2 || postcode || state) && !value) {
            return false;
          }
          return true;
        }),
      state: Yup.string()
        .matches(noHtmlTagRegex, translations.FormatError)
        .test('Billing-city-test', translations.EmptyErrorMsg, function (value) {
          const { countryCode, address, address2, postcode, city } = this.parent;
          if ((countryCode || address || address2 || postcode || city) && !value) {
            return false;
          }
          return true;
        }),
      postcode: Yup.string()
        .matches(noHtmlTagRegex, translations.FormatError)
        .test('Billing-postcode-test', translations.EmptyErrorMsg, function (value) {
          const { countryCode, address, address2, city, state } = this.parent;
          if ((countryCode || address || address2 || city || state) && !value) {
            return false;
          }
          return true;
        }),
      countryCode: Yup.string()
        .test('Billing-countryCode-test', translations.PleaseChoose, function (value) {
          const { address, address2, postcode, city, state } = this.parent;
          if ((address || address2 || postcode || city || state) && !value) {
            return false;
          }
          return true;
        }),
    }),
  });
};

export const registerProductValidationSchema = (translations) => {
  return Yup.object().shape({
    SerialNumber: Yup.string().required(translations.EmptyErrorMsg),
    PurchaseDate: Yup.string().required(translations.EmptyErrorMsg),
    Usage: Yup.string().required(translations.EmptyErrorMsg),
  });
};